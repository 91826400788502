<template>
  <div class="table-9-point-scale">
    <div class="flex columns align-center justify-center">
      <table>
      <thead>
      <tr>
        <th class="table-bg"></th>
        <th colspan="4" class="table-bg"><div><p>Overall Liking</p></div></th>
        <th colspan="4" class="table-bg"><div><p>Taste Liking</p></div></th>
        <th colspan="4" class="table-bg"><div><p>Naturalness</p></div></th>
        <th colspan="4" class="table-bg"><div><p>Aftertaste Liking</p></div></th>
        <th colspan="4" class="table-bg"><div><p>Aroma Liking</p></div></th>
      </tr>
      <tr>
        <th class="table-bg"></th>
        <th colspan="4">
          <div class="flex flex-between align-center">
            <span>Mean</span><span>Sign</span><span>T3%</span><span>L3%</span>
          </div>
        </th>
        <th colspan="4">
          <div class="flex flex-between align-center">
            <span>Mean</span><span>Sign</span><span>T3%</span><span>L3%</span>
          </div>
        </th>
        <th colspan="4">
          <div class="flex flex-between align-center">
            <span>Mean</span><span>Sign</span><span>T3%</span><span>L3%</span>
          </div>
        </th>
        <th colspan="4">
          <div class="flex flex-between align-center">
            <span>Mean</span><span>Sign</span><span>T3%</span><span>L3%</span>
          </div>
        </th>
        <th colspan="4">
          <div class="flex flex-between align-center">
            <span>Mean</span><span>Sign</span><span>T3%</span><span>L3%</span>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="row in chartData">
        <td class="row-header" v-html="row[0] + '<br/>' + row[21]"></td>
        <td colspan="4" >
          <div class="flex flex-between">
            <span :class="minMaxValues.q1.length > 1 && Math.min.apply(Math, minMaxValues.q1) === row[1] ? 'bg-red' :
                          minMaxValues.q1.length > 1 && Math.max.apply(Math, minMaxValues.q1) === row[1] ? 'bg-green' : ''">{{ row[1].toFixed(1).replace('.', ',') }}
            </span>
            <span>{{ row[2] }}</span><span>{{ row[3] }}</span><span>{{ row[4] }}</span>
          </div>
        </td>
        <td colspan="4">
          <div class="flex flex-between">
            <span :class="minMaxValues.q2.length > 1 && Math.min.apply(Math, minMaxValues.q2) === row[5] ? 'bg-red' :
                          minMaxValues.q2.length > 1 && Math.max.apply(Math, minMaxValues.q2) === row[5] ? 'bg-green' : ''">{{ row[5].toFixed(1).replace('.', ',') }}
            </span>
            <span>{{ row[6] }}</span><span>{{ row[7] }}</span><span>{{ row[8] }}</span>
          </div>
        </td>
        <td colspan="4" >
          <div class="flex flex-between">
            <span :class="minMaxValues.q3.length > 1 && Math.min.apply(Math, minMaxValues.q3) === row[9] ? 'bg-red' :
                          minMaxValues.q3.length > 1 && Math.max.apply(Math, minMaxValues.q3) === row[9] ? 'bg-green' : ''">{{ row[9].toFixed(1).replace('.', ',') }}
            </span>
            <span>{{ row[10] }}</span><span>{{ row[11] }}</span><span>{{ row[12] }}</span>
          </div>
        </td>
        <td colspan="4" >
          <div class="flex flex-between">
            <span :class="minMaxValues.q4.length > 1 && Math.min.apply(Math, minMaxValues.q4) === row[13] ? 'bg-red' :
                          minMaxValues.q4.length > 1 && Math.max.apply(Math, minMaxValues.q4) === row[13] ? 'bg-green' : ''">{{ row[13].toFixed(1).replace('.', ',') }}
            </span>
            <span>{{ row[14] }}</span><span>{{ row[15] }}</span><span>{{ row[16] }}</span>
          </div>
        </td>
        <td colspan="4" >
          <div class="flex flex-between">
            <span :class="minMaxValues.q5.length > 1 && Math.min.apply(Math, minMaxValues.q5) === row[17] ? 'bg-red' :
                          minMaxValues.q5.length > 1 && Math.max.apply(Math, minMaxValues.q5) === row[17] ? 'bg-green' : ''">{{ row[17].toFixed(1).replace('.', ',') }}
            </span>
            <span>{{ row[18] }}</span><span>{{ row[19] }}</span><span>{{ row[20] }}</span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
      <div class="general-description">
        <p> Mean | Sign. | in %</p>
        <p>Explanation: Significant differences between products are indicated by different letters (A≠B), (p≤0.10). Letter A states the higher value
          <span class="green">Green</span> for best prototype / concept of selection; <span class="red">Red</span> for worst prototype / concept of selection; <span class="white">White</span> all others.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Table9PointScale',
  props: {
    chartData: Array,
    bases: String
  },
  computed: {
    minMaxValues () {
      let obj = { q1: [], q2: [], q3: [], q4: [], q5: [],}
      this.chartData.forEach(row => {
        obj.q1.push(row[1])
        obj.q2.push(row[5])
        obj.q3.push(row[9])
        obj.q4.push(row[13])
        obj.q5.push(row[17])
      })
      return obj
    }
  }
}
</script>

<style lang="scss">
  .table-9-point-scale {
    margin-top: 50px;
    .table-bg {
      background: rgb(49, 47, 47);
      color: #FFFFFF;
    }
    table, th, td {
      border: 1px solid rgb(49, 47, 47);
      border-collapse: collapse;
    }
    table {
      tr th {
        height: 50px;
        min-width: 150px;
        width: 250px;
        div {
          height: 100%;
          width: 100%;
          border-left: solid 1px #fff;
          p {
            font-size: 18px;
            padding: 14px 0;
          }
          span {
            width: 25%;
            text-align: center;
          }
        }
      }
      tr td {
        height: 75px;
      }
      tbody {
        tr td:nth-child(1) {
          text-align: right;
          padding-right: 1rem;
        }
        tr td div {
          height: 100%;
          width: 100%;
          span {
            width: 25%;
            text-align: center;
            padding-top: 28px;
          }
        }
      }
    }
  }
</style>
