<template>
	<main class="login-view">
    <form @submit.prevent="handleLogin">
      <figure>
        <img src="../assets/logo.png" alt="">
      </figure>
      <label class="pointer">Email
        <v-text-field
            v-model="auth.username"
            placeholder="type your email..."
            single-line
            outlined
            clearable
            dense
        />
      </label>


      <label class="pointer">Password
        <v-text-field
            v-model="auth.password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            placeholder="type your password..."
            single-line
            clearable
            dense
            outlined
            @click:append="show = !show"
        />
      </label>
      <button class="button">Login</button>
      <p v-if="getErr" class="login-error">{{ getErr }}</p>
    </form>
	</main>
</template>

<script>
	export default {
		name: 'LoginView',
    data () {
      return {
        auth: {
          username: '',
          password: ''
        },
        show: false,
      }
    },
    computed: {
      getErr () {
        return this.$store.state.auth.loginErr
      }
    },
    methods: {
      handleLogin () {
        this.$store.dispatch('login', this.auth)
        this.auth.username = ''
        this.auth.password = ''
      }
    }
	}
</script>

<style lang="scss">
	.login-view {
    @media only screen and (min-width: 0) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      form {
        border-radius: 5px;
        width: 450px;
        height: 500px;
        padding: 2rem;
        box-shadow: 3px 3px 9px #ccc;

        figure img {
          display: block;
          margin: 40px auto 60px auto;
          width: 60%;
        }

        .v-text-field {
          padding-top: 0.5rem;
        }
      }
      .login-error {
        margin-top: 16px;
        color: #9D1C2B;
        font-size: 18px;
        font-weight: 500;
      }
    }
	}
</style>





