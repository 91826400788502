import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/base.scss';
import vuetify from './plugins/vuetify'
import HighchartsVue from 'highcharts-vue'
Vue.config.productionTip = false

import Highcharts from 'highcharts';

import loadWordcloud from 'highcharts/modules/wordcloud'
import accessibility from 'highcharts/modules/accessibility'

loadWordcloud(Highcharts);
accessibility(Highcharts);

Vue.use(HighchartsVue, { Highcharts });
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


