<template>
  <div class="text-center">
    <v-dialog
        v-model="dialogStage"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Attention
        </v-card-title>

        <v-card-text>
          {{ dialogText }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialogDisabled"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: "DialogBox",
    props: {
      dialog: Boolean,
      dialogText: String
    },
    data () {
      return {
        dialogStage: false
      }
    },
    methods: {
      dialogDisabled () {
        this.$emit('dialog-disabled', false)
      }
    },
    watch: {
      dialog (value) {
        this.dialogStage = value
      },
      dialogStage (value) {
        if (value === false) this.dialogDisabled()
      },
    }
  }
</script>




