import axios from 'axios'
import api from './api'
import router from "@/router"

// let $axios = axios.create()

function get_codes(state) {
    let arr_codes = []
    state.selected.forEach(elem => {
        arr_codes.push(state.direction[state.values.indexOf(elem)])
    })
    return arr_codes
}

function get_selection(codes, state) {
    let arr_values = []
    codes = JSON.parse(`"${codes}"`).split("_")
    codes.forEach(code => {
        arr_values.push(state.values[state.direction.indexOf(parseInt(code))])
    })
    return arr_values
}

export const state = function () {
    return {
        waves: [],
        menuOptions: {
            leftMenu: {
                waves: {
                    values: ['US Consumer Flavor Research', 'Project Burst Validation (W2)','Project Burst Validation (W1)', 'Winter Flavours (W2)', 'Summer Flavours (W1)',],
                    direction: [5, 4, 3, 2, 1],
                    selected: [],
                    bases: ['60', '70']
                },
                flavourDirection: {
                    values: [],
                    direction: [],
                    selected: []
                },
                flavours: {
                    values: [],
                    description: [],
                    direction: [],
                    selected: [],
                    selectedCodes: []
                },
            },
            mainMenu: {}
        },
        resultByAnalyze: [],
        chartDetails: {
            chartIsReady: undefined,
            chartType: 'Chart',
            chartTypeQ: '',
            chartCodes: [],
            menuName: '',
            label: '',
            chartDetailsForReq: {
                sortBy: '',
                codes: [],
                param: '',
                evaluation: ''
            },
        },
        sortingOptions: {
            chartSortOther: [
                {
                    label: 'Overall Liking',
                    sortBy: 'overall_liking'
                },
                {
                    label: 'Question',
                    sortBy: 'question'
                },
                {
                    label: 'Choice',
                    sortBy: 'choice'
                }
            ],
            chartSortFCE: [
                {
                    label: 'Appealing',
                    sortBy: 'appealing'
                },
                {
                    label: 'Question',
                    sortBy: 'question'
                },
                {
                    label: 'Choice',
                    sortBy: 'choice'
                }
            ],
            chartSortKpis: [
                {
                    label: 'Overall Liking',
                    sortBy: 'overall_liking'
                },
                {
                    label: 'Purchase Intention',
                    sortBy: 'purchase_intention'
                },
                {
                    label: 'Taste Liking ',
                    sortBy: 'taste_liking'
                }
            ],
            selectedOnFCE: {
                label: 'Appealing',
                sortBy: 'appealing'
            },
            selectedOnOther: {
                label: 'Overall Liking',
                sortBy: 'overall_liking'
            },
            selectedOnKpis: {
                label: 'Overall Liking',
                sortBy: 'overall_liking'
            },
        },
        ppt_loading: false
    }
}

export const actions = {
    async get_main_menu({commit}) {
        try {
            const {data} = await axios.get(api.MAIN_MENU)
            commit('SET_MAIN_MENU_DATA', data)
        } catch (err) {
            console.log(`err get results`, err)
            await router.push('/login')
        }
    },
    async get_analyze({state, commit}) {
        const waves = get_codes(state.menuOptions.leftMenu.waves)
        commit('SET_SELECTED_WAVES', waves)
        try {
            let url, chartName
            let req_body = {...state.chartDetails.chartDetailsForReq}

            if (router.currentRoute.query.section === 'flavour_concept_evaluation') req_body.sortBy = state.sortingOptions.selectedOnFCE.sortBy
            else req_body.sortBy = state.sortingOptions.selectedOnOther.sortBy

            if (req_body.evaluation === 'appealing' ||
                req_body.evaluation === 'purchase_intention_concept' ||
                req_body.evaluation === 'edition' ||
                req_body.evaluation === 'uniqueness') {
                delete req_body.codes

                let codes = get_codes(state.menuOptions.leftMenu.flavourDirection)
                req_body = {...req_body, codes}
            }

            if (req_body.evaluation === 'motive_terms') {
                delete req_body.codes
                delete req_body.param
                delete req_body.evaluation
                // delete req_body.sortBy

                let codes = get_codes(state.menuOptions.leftMenu.flavourDirection)
                req_body = {...req_body, codes}
            }
            if (req_body.evaluation === 'overview_rating') {
                delete req_body.codes
                delete req_body.param
                delete req_body.evaluation
                delete req_body.sortBy

                let directions = get_codes(state.menuOptions.leftMenu.flavourDirection)
                req_body = {...req_body, directions}
            }
            commit('SET_RESULT_STAGE', {
                key: 'chartIsReady',
                value: false
            })
            switch (state.chartDetails.chartDetailsForReq.evaluation) {
                case 'overall_liking':
                case 'taste_liking':
                case 'naturalness':
                case 'aftertaste_liking':
                case 'aroma_liking':
                    url = api.CHART_BAR_9_POINT
                    chartName = 'CT9PointScale'
                    break;
                case 'purchase_intention':
                case 'appealing':
                case 'purchase_intention_concept':
                case 'edition':
                case 'uniqueness':
                case 'expectation':
                case 'ranking':
                case 'closeness_flavour':
                    url = api.CHART_5_POINT_SCALE
                    chartName = 'CT5PointScale'
                    break;
                case 'five_charts':
                    url = api.FIVE_CHARTS
                    chartName = '5Charts5ps'
                    break;
                case 'taste_intensity':
                case 'sweetness':
                case 'aftertaste_intensity':
                case 'throat_hit':
                case 'nicotine_strength':
                case 'aroma_vapour':
                case 'flavour_question_one':
                case 'flavour_question_two':
                case 'flavour_question_three':
                case 'fruit_intensity':
                case 'amount_vapour':
                    url = api.CHART_JAR_SCALE
                    chartName = 'CTJarScale'
                    break;
                case 'flavour_descriptors':
                case 'like_descriptors':
                case 'dislike_descriptors':
                    url = api.CHART_WORD_CLOUD
                    chartName = 'CTWordcloud'
                    break;
                case 'motive_terms':
                    url = api.CHART_MOTIVES
                    chartName = 'CTMotives'
                    break;
                case 'overview_rating':
                    url = api.TABLE_5_POINT_SCALE
                    chartName = 'CTTable5PS'
                    break;
                case 'overview_likings':
                    url = api.TABLE_9_POINT_SCALE
                    chartName = 'CTTable9PS'
                    break;
                case 'overview_jar_b':
                    url = api.TABLE_9_JAR_B
                    chartName = 'CTTableJARB'
                    break;
                case 'overview_jar_a':
                    url = api.TABLE_9_JAR_A
                    chartName = 'CTTableJARA'
                    break;
                case 'overview_rating_perception':
                    url = api.TABLE_RATINGS
                    chartName = 'Ratings Table'
                    break;
                case 'flavour_specific_question':
                    url = api.CHART_SPECIFIC_QUESTION
                    chartName = 'specific_question'
                    break;
                default:
                // code block
            }
            const {data, status} = await axios.post(url, req_body)
            console.log(chartName)
            console.log(data)
            // const { data, status } = await axios.post(api.ANALYZE, codes)
            commit('SET_RESULT_BY_ANALYZE', data)
            if (status === 200) {
                commit('SET_RESULT_STAGE', {
                    key: 'chartIsReady',
                    value: true
                })
                commit('SET_RESULT_STAGE', {
                    key: 'chartType',
                    value: chartName
                })
            }
        } catch (err) {
            commit('SET_RESULT_STAGE', {
                key: 'chartIsReady',
                value: undefined
            })
            await router.push('/login')
            console.log(`err get results`, err)
        }
    },
    async get_kpis_analyze({state, commit}) {
        try {
            commit('SET_SUBTITLE', '')
            commit('SET_BASE', '')
            commit('SET_RESULT_STAGE', {
                key: 'chartIsReady',
                value: false
            })
            const field = state.menuOptions.leftMenu.flavourDirection
            let selectedCodes = {directions: [], sortBy: state.sortingOptions.selectedOnKpis.sortBy}
            selectedCodes.directions = get_codes(field)
            // selectedCodes.sortBy = state.chartDetails.chartDetailsForReq.sortBy
            if (selectedCodes.directions.length > 0) {
                const {data, status} = await axios.post(api.CHART_KPIS, selectedCodes)
                commit('SET_RESULT_BY_ANALYZE', data)
                if (status === 200) {
                    commit('SET_RESULT_STAGE', {
                        key: 'chartIsReady',
                        value: true
                    })
                    commit('SET_RESULT_STAGE', {
                        key: 'chartType',
                        value: 'kpis'
                    })
                }
            }
        } catch (err) {
            commit('SET_RESULT_STAGE', {
                key: 'chartIsReady',
                value: undefined
            })
            await router.push('/login')
            console.log(`err get results`, err)
        }
    },
    async get_results_on_load({commit, state, dispatch}) {
        try {
            await dispatch('get_main_menu')
            await dispatch('get_direction_by_waves', 1)

            const currentRoute = router.currentRoute.query
            if (currentRoute.section === 'kpis') dispatch('get_kpis_analyze')
            if (currentRoute.section === 'flavour_specific_question') dispatch('get_analyze')
            // Set menu item by url
            if (currentRoute.section !== 'kpis' &&
                currentRoute.section &&
                currentRoute.section !== 'flavour_specific_question') {
                let indexMenu
                let currentMenu = state.menuOptions.mainMenu[currentRoute.section]
                currentMenu.forEach((menu, index) => {
                    if (menu.evaluation === currentRoute.evaluation) {
                        indexMenu = index
                    }
                })
                commit('SET_SELECTED_QUESTION', {
                    objDetails: currentMenu[indexMenu],
                    menuName: currentRoute.section
                })
                dispatch('get_analyze')
            }
        } catch (err) {
            await router.push('/login')
            console.log(err)
        }
    },
    async get_pptx({commit, state}) {
        try {
            let filename = ''
            const url = `/api/pptx`
            commit('IS_LOADING', true)
            let obj = {
                codes: get_codes(state.menuOptions.leftMenu.flavours),
                directions: get_codes(state.menuOptions.leftMenu.flavourDirection),
                waves: get_codes(state.menuOptions.leftMenu.waves),
                sortByConcept: state.sortingOptions.selectedOnFCE.sortBy,
                sortByProduct: state.sortingOptions.selectedOnOther.sortBy
            }
            let payload = new FormData();
            obj.codes.forEach(element => payload.append('codes', element.toString()))
            obj.directions.forEach(element => payload.append('directions', element.toString()))
            obj.waves.forEach(element => payload.append('waves', element.toString()))
            payload.append('sortByConcept', obj.sortByConcept)
            payload.append('sortByProduct', obj.sortByProduct)

            function toDataURL(url) {
                return fetch(url, {
                    method: 'POST',
                    body: payload
                }).then((response) => {
                    const header = response.headers.get('content-disposition')
                    const parts = header.split(';');
                    filename = parts[1].split('=')[1].replaceAll('"', '');
                    if (response.status === 200) commit('IS_LOADING', false)
                    return response.blob();
                }).then(blob => {
                    return URL.createObjectURL(blob);
                });
            }

            const a = document.createElement('a');
            a.href = await toDataURL(url);
            a.download = `${filename}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a)
        } catch (err) {
            commit('IS_LOADING', false)
            await router.push('/login')
            console.log(err)
        }
    },
    async get_direction_by_waves({commit, state, dispatch}, number) {
        try {
            const field = state.menuOptions.leftMenu.waves
            const currentRoute = router.currentRoute.query
            let query = {...currentRoute}
            let values = []

            if (field.selected.length === 0 && !currentRoute.waves) {
                values.push(field.values[0])
                commit('SET_DEFAULT_SELECTION', {key: 'waves', value: values})
            } else if (field.selected.length === 0 && currentRoute.waves) {
                let codes = currentRoute.waves
                values = get_selection(codes, field)
                commit('SET_DEFAULT_SELECTION', {key: 'waves', value: values})
            }

            const param = {waves: get_codes(field)}

            const {data} = await axios.post(api.WAVES_DIRECTION, param)
            commit('SET_LEFT_MENU_OPTIONS', {key: 'flavourDirection', value: data})


            query['waves'] = JSON.stringify(param.waves.join("_")).replaceAll('"', '')
            if (JSON.stringify(query) !== JSON.stringify(currentRoute)) {
                await router.replace({query})
            }

            await dispatch('get_flavours_by_direction', number)
        } catch (err) {
            await router.push('/login')
            console.log(err)
        }
    },
    async get_flavours_by_direction({commit, state, dispatch}, number) {
        try {
            const field = state.menuOptions.leftMenu.flavourDirection
            const currentRoute = router.currentRoute.query
            let query = {...currentRoute}
            let values = []

            if (field.selected.length === 0 && !currentRoute.flavourDirection) {
                if (!number) values.push(field.values[0])
                else values = field.values
                commit('SET_DEFAULT_SELECTION', {key: 'flavourDirection', value: values})
            } else if (field.selected.length === 0 && currentRoute.flavourDirection) {
                let codes = router.currentRoute.query.flavourDirection
                values = get_selection(codes, field)
                commit('SET_DEFAULT_SELECTION', {key: 'flavourDirection', value: values})
            }

            const param = {directions: get_codes(field)}

            // Send request to server
            const {data} = await axios.post(api.FLAVOURS_DIRECTION, param)
            commit('SET_LEFT_MENU_OPTIONS', {key: 'flavours', value: data})

            let flavours = state.menuOptions.leftMenu.flavours
            let arr_codes = [], valuesFlavours = []
            if (currentRoute.flavours) {
                arr_codes = router.currentRoute.query.flavours
                valuesFlavours = get_selection(arr_codes, flavours)
                arr_codes = JSON.parse(`"${arr_codes}"`).split("_")
            } else {
                flavours.values.forEach((element, index) => {
                    if (index < 1 && !number) valuesFlavours.push(element)
                    else if (index < 5 && number) valuesFlavours.push(element)
                })
                valuesFlavours.forEach(elem => {
                    arr_codes.push(flavours.direction[flavours.values.indexOf(elem)])
                })
            }

            commit('SET_DEFAULT_SELECTION', {key: 'flavours', value: valuesFlavours, codes: arr_codes})

            query['flavourDirection'] = JSON.stringify(param.directions.join("_")).replaceAll('"', '')
            if (arr_codes.length > 0) query['flavours'] = JSON.stringify(arr_codes.join("_")).replaceAll('"', '')

            if (JSON.stringify(query) !== JSON.stringify(currentRoute)) {
                await router.replace({query})
            }
        } catch (err) {
            await router.push('/login')
            console.log(err)
        }
    },
}

export const mutations = {
    SET_SELECTED_WAVES(state, data) {
        state.waves = data
    },
    SET_MAIN_MENU_DATA(state, data) {
        state.menuOptions.mainMenu = data
    },
    SET_LEFT_MENU_OPTIONS(state, {key, value}) {
        const leftMenu = state.menuOptions.leftMenu
        if (key === 'flavourDirection') {
            leftMenu.flavourDirection.values = []
            leftMenu.flavourDirection.direction = []
            leftMenu.flavourDirection.selected = []
            leftMenu.flavours.values = []
            leftMenu.flavours.direction = []
            leftMenu.flavours.selected = []
            value.forEach(row => {
                leftMenu[key].values.push(row.name)
                leftMenu[key].direction.push(row.direction)
            })
        } else if (key === 'flavours') {
            leftMenu.flavours.values = []
            leftMenu.flavours.direction = []
            leftMenu.flavours.description = []

            leftMenu.flavours.selected = []
            leftMenu.flavours.selectedCodes = []
            state.chartDetails.chartDetailsForReq.codes = []
            value.forEach(row => {
                leftMenu[key].values.push(row.Description_Short)
                leftMenu[key].direction.push(row.Product_Code)
                leftMenu[key].description.push(row.Description)
            })
        }
    },
    SET_RESULT_BY_ANALYZE(state, value) {
        console.log(value)
        state.resultByAnalyze = value
    },
    SET_RESULT_STAGE(state, {key, value}) {
        state.chartDetails[key] = value
    },
    SET_LEFT_MENU(state, {key, value}) {
        state.menuOptions.leftMenu[key].selected = value
        if (key === 'flavours') {
            const obj = state.menuOptions.leftMenu[key]
            // obj.selectedCodes = []
            obj.selectedCodes = get_codes(obj)
            state.chartDetails.chartDetailsForReq.codes = obj.selectedCodes
        }
    },
    SET_SELECTED_QUESTION(state, {objDetails, menuName}) {
        state.chartDetails.menuName = menuName
        state.chartDetails.label = objDetails.label
        state.chartDetails.chartTypeQ = objDetails.chart_type
        state.chartDetails.chartDetailsForReq.evaluation = objDetails.evaluation
        state.chartDetails.chartDetailsForReq.param = objDetails.param
        state.chartDetails.chartIsReady = undefined
    },
    SET_DEFAULT_SELECTION(state, {key, value, codes}) {
        state.menuOptions.leftMenu[key].selected = value
        if (key === 'flavours' && codes) {
            state.menuOptions.leftMenu[key].selectedCodes = codes
            state.chartDetails.chartDetailsForReq.codes = codes
        }
    },
    SET_SORT_BY(state, {item, sortingArray}) {
        if (sortingArray === 'flavour_concept_evaluation') {
            state.sortingOptions.selectedOnFCE = item
        } else if (sortingArray === 'kpis') {
            state.sortingOptions.selectedOnKpis = item
        } else {
            state.sortingOptions.selectedOnOther = item
        }
    },
    IS_LOADING(state, value) {
        state.ppt_loading = value
    }
}
