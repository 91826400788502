<template>
  <div class="multiple-select">
    <span>{{ title }}</span> <div v-if="getQuerySection"></div>
    <div class="select-wrapper">
      <v-select
          v-model="selectedValues"
          :items="options.values"
          :label="label"
          @change="handleChangeValue"
          @blur="handleClose"
          multiple
          solo
          dense
          :disabled="disable"
      >
        <template v-slot:selection="{ item, index }" >
          <span v-if="index === 0">
            <span>{{ item }}</span>
          </span>
          <span v-if="index === 1"
                class="grey--text text-caption">
            ...(+{{ selectedValues.length - 1 }})
          </span>
        </template>

        <template v-if="countList" v-slot:item="{ active, item, index, attrs, on }">
          <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters align="center">
                  <span>{{ item }}</span>
                  <v-spacer></v-spacer>
                  <v-chip v-if="selectedValues.includes(item)"
                          :color="item.color"
                          small
                          class="chips-re"
                  >
                    <span>{{ selectedValues.indexOf(item) + 1}} </span>
                  </v-chip>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleSelect',
  props: {
    options: Object,
    title: String,
    label: String,
    objectKey: String,
    disable: Boolean,
    selectedValuesArr: Number,
    countList: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    selectedValues: []
  }),

  computed: {
    getQuerySection () {
      let fieldCodes = this.$route.query[this.objectKey]
      if (fieldCodes) {
        let val = [], codes = []
        // Convert string to array and split separator
        codes = JSON.parse(`"${fieldCodes}"`).split("_")
        // Get name where name index === code index
        codes.forEach(code => {
          // Get code direction where index selection === index code
          val.push(this.options.values[this.options.direction.indexOf(parseInt(code))])
        })
        this.selectedValues = val
      }
      if (!fieldCodes) {
        const currentQuery = this.$route.query
        delete currentQuery[this.objectKey]
        const query = { ...currentQuery}
        this.selectedValues = []
        if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
          this.$router.replace({query}) // Replace old route with new route
        }
      }

      if (this.$route.query.section === 'kpis') return true
      if (this.$route.query.section === 'flavour_concept_evaluation') return true
    },
  },

  methods: {
    handleChangeValue (items) {
      if (items.length > 2 && this.objectKey === 'waves') {
        this.selectedValues.pop()
        this.$emit('handle-dialog', {
          dialog: true,
          dialogText: 'You cannot select more than 2 waves'
        })
      }
      if (items.length > 5 && this.objectKey === 'flavours') {
        this.selectedValues.pop()
        this.$emit('handle-dialog', {
          dialog: true,
          dialogText: 'You cannot select more than 5 flavours'
        })
      }


    },

    handleClose () {
      if (this.selectedValues.toString() !== this.options.selected.toString()) {
        this.$emit('handle-selected-code-and-name', {
          name: this.selectedValues,
          objectKey: this.objectKey
        })

        let query = this.$route.query

        const selectedCodes = []
        this.selectedValues.forEach(item => {
          // Get code direction where index selection === index code
          selectedCodes.push(this.options.direction[this.options.values.indexOf(item)])
        })
        const myArrString = JSON.stringify(selectedCodes.join("_")).replaceAll('"', '')
        const currentQuery = this.$route.query
        query = {...currentQuery, [this.objectKey]: myArrString}
        if (this.selectedValues.length === 0) delete query[this.objectKey]
        if (this.objectKey === 'waves') {
          delete query.flavourDirection
          delete query.flavours
        }
        if (this.objectKey === 'flavourDirection') {
          delete query.flavours
        }
        if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
          this.$router.replace({query}) // Replace old route with new route
        }
        this.$emit('handle-blur-selections', this.objectKey)
      }
    },

    handleDialogDisabled (value) {
      this.dialog = value
    }
  },

  watch: {
    selectedValuesArr: function (val) {
      if (val === 0) this.selectedValues = []
    }
  }
}
</script>

<style lang="scss">
@import "../styles/var";
.multiple-select {

  .set-title-inactive {
    opacity: 0.6;
  }
  .select-wrapper {
    margin-top: 1rem;

    // added by andrei doman to make the select box wider
    width: 260px;

    // modify arrow to bottom from select input
    .v-input__icon .theme--light {
      color: $primary;
    }
  }
}
.chips-re {
  background: $primary !important;
  color: #fff !important;
  border-radius: 9999px;
  padding: 5px 9px;
}
</style>




