import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      auth: true
    },
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(({name, meta}, from, next) => {
  const user = store.state.auth.user
  if (name === 'login') {
    user.name ? next('/') : next();
  } else {
    meta.auth && !user.name
      ? store.dispatch('check_login', { next })
      : next();
  }
});

export default router



