import axios from 'axios'
import api from './api'
import router from "@/router"

// let $axios = axios.create()

export const state = function () {
  return {
    user: {},
    loginErr: ''
  }
}

export const actions = {
  async login ({commit}, user) {
    try {
      commit('SET_ERR', '')
      await axios.post(api.LOGIN, user)
      await router.push('/')
    } catch (err) {
      commit('SET_ERR', 'The email address or password is incorrect.')
      console.log(`err login`, err)
    }
  },
  async check_login ({commit}, { next }) {
    try {
      const { data } = await axios.get(api.CHECK_LOGIN)
      commit('SET_USER', data)
      next()
    } catch (err) {
      await next('/login')
    }
  },
  async logout ({commit}) {
    try {
      await axios.delete(api.LOGOUT)
      commit('SET_USER', {})
      await router.push('/login');
    } catch (err) {
      console.log(err)
      // await router.push('/login');
    }
  },
}

export const mutations = {
  SET_USER (state, user) {
    state.user = user
  },
  SET_ERR (state, err) {
    state.loginErr = err
  },
}




