<template>
  <div class="stacked-jar-chart-wrapper">
    <div class="chart-questions">
      <h3 class="title">{{ chartData.Question }}</h3>
      <p >{{ chartData.ScaleDescription }}</p>
    </div>
    <div class="flex columns align-center justify-center">
      <div class="flex-wrapper flex justify-center">
        <div class="chart-container">
          <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
        </div>
        <div>
          <table>
            <thead>
            <tr>
              <th>Sig.</th>
              <th>Penalty</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="data in chartData.rows">
                <td v-if="data.jarSign === ''"><span><br></span></td>
                <td v-else>{{ data.jarSign }}</td>
                <td :class="`bg-${data.color}`">{{ data.color === 'red' ? data.penalty.replace('.', ',') :
                                                   data.color === 'yellow' ? data.penalty.replace('.', ',') : '' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="general-description">
        <p> in % | JAR Sig. | Penalty</p>
        <p>(5-point-scale: ++ // + // 0 // - // -- )</p>
        <p>
          <span class="green">Green</span> if skews ≤ 15% and JAR ≥ 66% || skews ≥ 15% and JAR ≥ 66% and Penalty < 0.2; <span class="yellow">Yellow</span> if skews ≥ 15% and Penalty ≥ 0.2 BUT ≤ 0.6; <span class="red">Red</span> if skews ≥ 15% and Penalty  ≥  0.6; White all others.
        </p>
          <!--
    <p>-->
<!--          Explanation: Significant differences between products are indicated by different letters (A≠B), (p≤0.10). Letter A states the higher value-->
<!--          Remark: Penalties on overall opinion are highlighted for skews ≥ 15% and Penalty ≥ 0.2-->
<!--          <span class="green">Green</span> if Penalty ≤ 0.2 and skews ≤ 15%; <span class="yellow">Yellow</span> if Penalty ≥ 0.2 BUT ≤ 0.6; <span class="red">Red</span> if Penalty > 0.6; White all others.-->
<!--        </p>-->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ChartJarStackedBar",
    props: {
      chartData: {
        type: Object,
        default: undefined,
        required: true
      },
      bases: String
    },

    computed: {
      chartOptions () {
        return {
          chart: {
            type: 'bar',
            height: this.chartData.rows.length > 2 ? 85 * this.chartData.rows.length : 200
          },
          credits: {
            enabled: false
          },
          title: {
            text: ''

          },
          xAxis: {
            categories: this.chartDetails.barName,
            labels: {
              style: {
                fontSize: 18 + 'px',
                color: '#595959'
              }
            }
          },
          yAxis: {
            // min: 0,
            max: 100,
            // tickInterval: 20,
            visible: false,
            title: {
              enabled: false
            },
          },
          tooltip: {
            formatter: function() {
              return 'The value for <b>'+ this.x +
                  '</b> is <b>'+ this.y +'%</b>';
            }
          },
          legend: {
            reversed: true,
            symbolHeight: .001,
            symbolWidth: .001,
            useHTML: true,
            labelFormatter: function () {
                return '<div class="hc-legend" style="background-color:'+this.color+';">'+ this.name +'</div>';
            },
            inactiveColor: '#cccccc'
          },
          plotOptions: {
            series: {
              // stacking: 'normal',
              stacking: 'percent',
              pointWidth: 45,
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  fontSize: 20 + 'px',
                  color: "#fff"
                }
              },
              showInLegend: true
            },
          },
          series: this.chartBarsValue
        }
      },

      chartDetails () {
        let array = { barName: []}
        const analyze = this.chartData
        analyze.rows.forEach(value => {
          array.barName.push(value.name + '<br>' + value.subtitle)
        })
        return array
      },

      chartBarsValue () {
        let obj = []
        this.chartData.rows.forEach(row => {
          let arr = { data: undefined}
          arr.data = Object.keys(row.percentages).map((key) => [Math.round(row.percentages[key])])
          arr.data = arr.data.flat(2)
          obj.push(arr)
        })
        let series = [{data: [], index: 5, color: "#525252", name: "--"},
          {data: [], index: 4, color: "#7C7C7C", name: "-"},
          {data: [], index: 3, color: "#455D10", name: "JAR"},
          {data: [], index: 2, color: "#B45600", name: "+"},
          {data: [], index: 1, color: "#783A00", name: "++"}]
        obj.forEach(elem => {
          elem.data.forEach((val, index )=> {
            series[index].data.push(val)
          })
        })
        return series
      }
    }
  }
</script>

<style lang="scss">
.stacked-jar-chart-wrapper {
  .highcharts-legend-item-hidden {
    opacity: 0.4 !important;
  }
  margin: 16px 0 0 0;
  .flex-wrapper {
    .chart-container {
      width: 1150px;
      min-width: 300px;
    }
    table {
      height: calc(100% - 50px);
      margin-right: 4rem;
      margin-top: -30px;

      .bg- {
        background: #7C7C7C;
        width: 50px;
      }
      .bg-green {
        background: #8BB920;
        width: 50px;
      }
      .bg-yellow {
        background: #FFC000;
        width: 50px;
      }
      .bg-red {
        background: #9D1C2B;
        color: #FFFFFF;
        width: 50px;
      }


      thead tr th {
        height: 30px;
        font-weight: unset;
      }
      tbody tr td {
        text-align: center;
        width: 100px;
      }
    }
  }

}
.hc-legend {
  width: 70px;
  font-size: 1.15rem;
  border-radius: 7px;
  color: #fff;
  text-align: center;
  padding: 8px 0;
  margin: 0;
}

//.highcharts-legend-item > span > span {
//}
//.highcharts-legend-item-hidden > span > span {
//}

</style>



