<template>
  <main class="home-view">
    <TheHeader :user="user"
               @handle-logout="handleLogout"
               @handle-download-pdf="handleDownloadPdf"
               :pptxLoading="pptLoading"
    />
    <TheNavbar :mainMenuOptions="mainMenuOptions"
               :chartDetails="chartDetails"
               @handle-set-item="handleSetQuestion"
    />

    <div class="content-wrapper">
      <section>
        <MultipleSelect :options="leftMenuOption.waves"
                        title="Please select up to two waves:"
                        label="Waves"
                        :disable="false"
                        objectKey="waves"
                        :selectedValuesArr="leftMenuOption.waves.selected.length"
                        @handle-selected-code-and-name="handleSelectedCodeAndName"
                        @handle-blur-selections="handleBlurSelection"
                        @handle-dialog="handleDialog"
        />

        <MultipleSelect :options="leftMenuOption.flavourDirection"
                        title="Please select the flavour direction:"
                        :disable="leftMenuOption.waves.selected.length === 0"
                        objectKey="flavourDirection"
                        label="Flavour Direction"
                        :selectedValuesArr="leftMenuOption.flavourDirection.selected.length"
                        @handle-selected-code-and-name="handleSelectedCodeAndName"
                        @handle-blur-selections="handleBlurSelection"
                        :countList="true"
        />
        <hr/>
        <MultipleSelect :options="leftMenuOption.flavours"
                        title="Optional: Select the flavours and their order:"
                        :disable="leftMenuOption.flavourDirection.selected.length === 0 || leftMenuOption.waves.selected.length === 0"
                        objectKey="flavours"
                        label="Flavour"
                        :countList="true"
                        :selectedValuesArr="leftMenuOption.flavours.selected.length"
                        @handle-selected-code-and-name="handleSelectedCodeAndName"
                        @handle-blur-selections="handleBlurSelection"
                        @handle-dialog="handleDialog"
        />

        <button class="button"
                @click="handleAnalyze">Analyze
        </button>
      </section>
      <section>
        <div class="flex flex-between align-center m-t-16 sort-header-wrapper">
          <div>
            <h3 class="select-question-title">{{ getSpecialSelectionsWaves || 'Select the question' }}</h3>
            <!--          <h2>{{leftMenuOption.waves[leftMenuOption.waves.selected]}}</h2>-->
<!--            <h4 class="m-t-16" v-if="getWaves.length > 1 && getWaves.includes(3)">In testing mode</h4>-->
<!--            <h2 class="mt-2" v-else>Not testing</h2>-->
          </div>
          <SortByOptions class="dropdown-sort-by"
                         @handle-select-sort-by="handleSortType"
                         :sortOptions="getQuery.section === 'flavour_concept_evaluation' ? sortBy.chartSortFCE :
                                       getQuery.section === 'kpis' ? sortBy.chartSortKpis : sortBy.chartSortOther"
                         :sortBy="getQuery.section === 'flavour_concept_evaluation' ? sortBy.selectedOnFCE :
                                  getQuery.section === 'kpis' ? sortBy.selectedOnKpis :sortBy.selectedOnOther"
          />
        </div>
        <Table5PointScale v-if="chartDetails.chartIsReady && chartDetails.chartType === 'CTTable5PS'"
                          :chartData="resultByAnalyze"
        />
        <Table9PointScale v-if="chartDetails.chartIsReady && chartDetails.chartType === 'CTTable9PS'"
                          :chartData="resultByAnalyze"
        />
        <TableRatings v-if="chartDetails.chartIsReady && chartDetails.chartType === 'Ratings Table'"
                      :chartData="resultByAnalyze"
        />
        <TableJar v-if="chartDetails.chartIsReady && chartDetails.chartType === 'CTTableJARB' ||
                        chartDetails.chartIsReady && chartDetails.chartType === 'CTTableJARA'"
                  :chartData="resultByAnalyze"
                  :chartType="chartDetails.chartType"
        />
        <ChartKpis v-if="chartDetails.chartIsReady && chartDetails.chartType === 'kpis'"
                   :chartData="resultByAnalyze"
        />
        <ChartBar v-if="chartDetails.chartIsReady && chartDetails.chartType === 'CT9PointScale'"
                  :chartData="resultByAnalyze"
        />
        <ChartStackedBar v-if="chartDetails.chartIsReady && chartDetails.chartType === 'CT5PointScale'"
                         :chartData="resultByAnalyze"
                         :evaluation="chartDetails.chartDetailsForReq.evaluation"
        />
        <div v-if="chartDetails.chartIsReady && chartDetails.chartType === '5Charts5ps'">
          <ChartStackedBar
              v-for="(item, index) in resultByAnalyze"
              :key="index"
              :chartData="item"
              :evaluation="chartDetails.chartDetailsForReq.evaluation"
          />
        </div>
        <ChartJarStackedBar v-if="chartDetails.chartIsReady && chartDetails.chartType === 'CTJarScale'"
                            :chartData="resultByAnalyze"
        />
        <div v-if="chartDetails.chartIsReady && chartDetails.chartType === 'CTWordcloud'">
          <div class="chart-questions">
            <h3 class="title">{{ resultByAnalyze.Question }}</h3>
            <p>{{ resultByAnalyze.ScaleDescription }}</p>
          </div>
          <div v-for="row in resultByAnalyze.rows">
            <ChartWordCloud :chartData="row" :question="resultByAnalyze.Header"/>
          </div>
        </div>
        <div v-if="chartDetails.chartIsReady && chartDetails.chartType === 'CTMotives'">
          <div class="chart-questions">
            <h3 class="title">{{ resultByAnalyze.Question }}</h3>
            <p>{{ resultByAnalyze.ScaleDescription }}</p>
          </div>
          <div v-for="row in resultByAnalyze.rows">
            <ChartMotives :chartData="row"
            />
          </div>
          <ChartLines :chartData="resultByAnalyze"
                      v-if="chartDetails.chartIsReady && chartDetails.chartType === 'CTMotives'"
          />
        </div>
        <div v-for="row in resultByAnalyze"
             v-if="chartDetails.chartIsReady && chartDetails.chartType === 'specific_question'"
             class="specific-question-chart-margins">
          <p>{{ row.Header }}</p>
          <ChartJarStackedBar :chartData="row"
          />
        </div>
        <DialogBox :dialog="dialog"
                   :dialogText="dialogText"
                   @dialog-disabled="handleDialogDisabled"
        />
      </section>
    </div>

    <v-row justify="center">
      <v-dialog
          v-model="chartDetails.chartIsReady === false"
          persistent
          max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            Please wait...
          </v-card-title>
          <div class="flex justify-center">
            <v-progress-circular
                indeterminate
                class="loading"
                color="primary"
            ></v-progress-circular>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </main>
</template>

<script>

import {mapState} from 'vuex'
import TheHeader from '@/components/TheHeader'
import TheNavbar from '@/components/TheNavbar'
import ChartKpis from '@/components/ChartKpis'
import ChartBar from '@/components/ChartBar'
import ChartStackedBar from '@/components/ChartStackedBar'
import ChartJarStackedBar from '@/components/ChartJarStackedBar'
import ChartWordCloud from '@/components/ChartWordCloud'
import ChartMotives from '@/components/ChartMotives'
import ChartLines from '@/components/ChartLines'
import MultipleSelect from '@/components/MultipleSelect'
import DropdownSelect from '@/components/DropdownSelect'
import SortByOptions from '@/components/SortByOptions'
import DialogBox from '@/components/DialogBox'
import Table5PointScale from '@/components/Table5PointScale'
import Table9PointScale from '@/components/Table9PointScale'
import TableJar from '@/components/TableJar'
import TableRatings from '@/components/TableRatings'

export default {
  name: 'HomeView',
  components: {
    Table5PointScale,
    Table9PointScale,
    TableJar,
    TableRatings,
    TheNavbar,
    TheHeader,
    DropdownSelect,
    SortByOptions,
    MultipleSelect,
    ChartBar,
    ChartKpis,
    ChartStackedBar,
    ChartJarStackedBar,
    ChartWordCloud,
    ChartMotives,
    ChartLines,
    DialogBox
  },
  data() {
    return {
      dialog: false,
      dialogText: '',
      showLoading: false,
      chartInProgress: true,
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      chartDetails: state => state.results.chartDetails,
      resultByAnalyze: state => state.results.resultByAnalyze,
      mainMenuOptions: state => state.results.menuOptions.mainMenu,
      leftMenuOption: state => state.results.menuOptions.leftMenu,
      sortBy: state => state.results.sortingOptions,
      pptLoading: state => state.results.ppt_loading,
    }),



    getQuery() {
      return this.$route.query
    },

    getSpecialSelectionsWaves() {
      let section = this.$route.query.section
      // // Return menu name
      if (!section || section === 'kpis') return 'KPIs'
      else return this.chartDetails.label
    },
  },

  mounted() {
    if (!this.getQuery.section || this.getQuery.section === 'kpis') {
      this.$store.commit('SET_SELECTED_QUESTION', {
        objDetails: {},
        menuName: 'kpis'
      })
    }
    if (this.getQuery.section === 'flavour_specific_question') {
      this.$store.commit('SET_SELECTED_QUESTION', {
        objDetails: {evaluation: 'flavour_specific_question', label: 'Flavour Specific Questions'},
        menuName: 'flavour_specific_question'
      })
    }
  },

  created() {
    if (!this.$route.query.section) {
      let query = {section: 'kpis'}
      this.$router.replace({query})
    }
    this.$store.dispatch('get_results_on_load')
  },

  methods: {
    handleLogout() {
      this.$store.dispatch('logout')
    },
    handleAnalyze() {

      if (this.$route.query.section === 'kpis' || !this.$route.query.section) {
        this.$store.dispatch('get_kpis_analyze')
      }

      if (this.getQuery.section === 'general_evaluation' &&
          this.chartDetails.chartDetailsForReq.codes.length === 0 ||
          this.getQuery.section === 'taste_evaluation' &&
          this.chartDetails.chartDetailsForReq.codes.length === 0 ||
          this.getQuery.section === 'vapour_and_nicotine_perception' &&
          this.chartDetails.chartDetailsForReq.codes.length === 0 ||
          this.getQuery.section === 'flavour_specific_question' &&
          this.chartDetails.chartDetailsForReq.codes.length === 0
      ) {
        this.dialog = true
        this.dialogText = 'Please select the flavours'
      }

      if (this.leftMenuOption.flavours.selected.length > 0 &&
          this.getQuery.section !== 'kpis' ||
          this.getQuery.section === 'flavour_concept_evaluation'
      ) {
        this.$store.dispatch('get_analyze')
      }
    },

    handleSelectedCodeAndName({name, objectKey}) {
      this.$store.commit('SET_LEFT_MENU', {
        key: objectKey,
        value: name
      })
    },

    handleBlurSelection(objectKey) {
      if (objectKey === 'waves') {
        this.$store.dispatch('get_direction_by_waves')
      } else if (objectKey === 'flavourDirection') {
        this.$store.dispatch('get_flavours_by_direction')
      }
    },

    handleSetQuestion({objDetails, menuName}) {
      this.$store.commit('SET_SELECTED_QUESTION', {
        objDetails,
        menuName
      })
      this.handleAnalyze()
    },

    handleSortType({item, sortingArray}) {
      this.$store.commit('SET_SORT_BY', {item, sortingArray})
      this.handleAnalyze()
    },

    handleDialogDisabled(value) {
      this.dialog = value
    },

    handleDownloadPdf() {
      if (this.leftMenuOption.flavours.selected.length === 0) {
        this.dialog = true
        this.dialogText = 'Please select the flavours'
      } else {
        this.$store.dispatch('get_pptx')
      }
    },

    handleDialog({dialog, dialogText}) {
      this.dialog = dialog
      this.dialogText = dialogText
    }
  }
}
</script>

<style lang="scss">
@import "../styles/var";

.home-view {
  height: 100%;
  width: 100%;
  position: fixed;

  .specific-question-chart-margins {
    margin-top: 50px;

    p {
      font-weight: 500;
      margin: 0;
    }
  }

  .select-question-title {
    color: $primary
  }

  @media only screen and (min-width: 0) {
    .content-wrapper {
      display: grid;
      grid-template-columns: 300px calc(100% - 300px);
      height: 100%;

      section {
        padding: 24px;
      }

      section:first-child {
        border-right: 1px solid lightgray;

        hr {
          margin-bottom: 1rem;
        }
      }

      section:last-child {
        height: calc(100% - 125px);
        overflow-x: scroll;
        border-right: 1px solid lightgray;

        hr {
          margin-bottom: 1rem;
        }

        .sort-header-wrapper {
          margin-bottom: 65px;
        }

        .dropdown-sort-by {
          border: solid 1px #ccc;
        }
      }
    }
  }
}
</style>




