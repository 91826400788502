<template>
  <div class="chart-lines-wrapper flex justify-center columns align-center">
    <div class="chart-wrapper">
      <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
    </div>
    <div class="general-description">
      <p> in % | Sig.</p>
      <p>Explanation: Significant differences between products are indicated by different letters (A≠B), (p≤0.05). Letter A states the higher value.</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChartLines',
    props: {
      chartData: {
        type: Object,
        default: undefined,
        required: true
      },
      bases: String
    },
    data () {
      return {
        order: ['familiar', 'reliable', 'high quality', 'adventurous', 'exotic',
          'fun to vape', 'natural', 'honest', 'premium', 'crazy', 'creative', 'tempting',
          'pure', 'functional', 'special', 'extravagant', 'stimulating', 'sensual']
      }
    },
    computed: {
      chartOptions () {
        return {
          chart: {
            height: 600,
          },
          credits: {
            enabled: false
          },
          title: {
            text: 'Flavour direction in %',
            align: 'left',
            style: {
              fontSize: 26 + 'px',
            },
            margin: 30
          },

          xAxis: {
            categories: this.order,
            labels: {
              style: {
                fontSize: 18 + 'px',
                color: '#595959'
              },
              rotation: -45

            },
          },
          yAxis: {
            min: 0,
            tickInterval: 10,
            title: {
              text: null
            },
            labels: {
              style: {
                fontSize: 18 + 'px',
                color: '#595959'
              }
            },
            plotLines: [{
              value: 0,
              width: 1,
              color: '#808080'
            }],
          },


          legend: {
            // layout: 'vertical',
            // align: 'right',
            // verticalAlign: 'middle',
            borderWidth: 0,
            showInLegend: false,
            itemStyle: {
              fontSize: '18px'
            }
          },
          series: this.chartDetails
        }
      },

      chartCategories () {
        let array = []
        for (const [key, value] of Object.entries(this.chartData.rows[0].percentages)) {
          array.push(key)
        }
        return array
      },

      chartDetails () {
        // let order = ['familiar', 'reliable', 'high quality', 'adventurous', 'exotic',
        //   'fun to vape', 'natural', 'honest', 'premium', 'crazy', 'creative', 'tempting',
        //   'pure', 'functional', 'special', 'extravagant', 'stimulating', 'sensual']
        let array = []
        this.chartData.rows.forEach(row => {
          // let values = { data: [], name: row.name}
          // for (const [key, value] of Object.entries(row.percentages)) {
          //   values.data.push(+value)
          // }
          // array = [ ...array, values]

          let values = { data: [], name: row.name}
          this.order.forEach(key => {
            values.data.push(row.percentages[key])
          })
          array = [ ...array, values]
        })
        return array
      }
    },
  }
</script>

<style lang="scss">
  .chart-lines-wrapper {
    gap: 1rem;
    margin-top: 50px;
    width: 98%;
    .chart-wrapper {
      width: 1350px;
      min-width: 300px;
    }
  }
</style>





