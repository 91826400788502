<template>
  <div class="dropdown-select">
    <v-list-group v-model="setDisplayMenu"
                  v-click-outside="onClickOutside">
      <!--      prepend-icon="mdi-account-circle"-->
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <div class="dropdown-options">
        <v-list-item
            v-for="(option, i) in options"
            :key="i"
            @click="handleSelectQuestion(option)"
            :class="{'active-question-evaluation' : getActiveQuestion === option.evaluation}"
        >
          <v-list-item-title v-text="option.label" ></v-list-item-title>
        </v-list-item>
      </div>
    </v-list-group>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "DropdownSelect",
  props: {
    title: String,
    options: Array,
    menuName: {
      type: String,
      default: 'dropdown_select_item'
    }
  },
  data () {
    return {
      setDisplayMenu: false
    }
  },
  computed: {
    getActiveQuestion () {
      return this.$route.query.evaluation
    }
  },
  methods: {
    handleSelectQuestion (item) {
      // delete item.label
      // delete item.chart_type
      //
      // const currentQuery = this.$route.query
      // const query = { ...currentQuery, section: this.menuName, ...item}
      // this.$router.replace({ query })

      const currentQuery = this.$route.query
      const query = { ...currentQuery,
        section: this.menuName,
        evaluation: item.evaluation
      }
      if (JSON.stringify(query) !== JSON.stringify(currentQuery)) {
        router.replace({query})
      }
      this.setDisplayMenu = false // close menu on select question
      this.$emit('handle-select-item', {
        objDetails: item,
        menuName: this.menuName
      })
    },

    onClickOutside () {
      this.setDisplayMenu = false
    }
  }
}
</script>

<style lang="scss">
  @import "../styles/var";

  .dropdown-select {
    min-width: 150px;
    width: 350px;
    position: relative;
    .active-question-evaluation {
      background: rgb(253, 238, 223);
      box-shadow: 3px 0 0 0 $primary inset;
    }
    .dropdown-options {
      z-index: 99999;
      position: absolute;
      background: #FFFFFF;
      width: 100%;
      border: solid 1px #ccc;
    }


  }
</style>



