<template>
  <div class="the-loading text-center">
    <v-alert class="alert-box"
             shaped
             dense
             v-model="showLoading">
      Downloading pptx report. This can take several minutes. In the meantime, you can still use the tool for any actions.
      <v-progress-circular
          indeterminate
          class="loading"
          color="primary"
      ></v-progress-circular>
    </v-alert>
  </div>
</template>

<script>
  export default {
    name: 'TheLoading',
    props: {
      showLoading: {
        type: Boolean,
        default: false,
      }
    },
    data: () => ({
      loading: false,
    }),
    watch: {
      showLoading (val) {
        if (!val) return

        setTimeout(() => {
          this.$emit('download-disabled', false)
        }, 5000)
      },
    },
  }
</script>

<style lang="scss">
  @import "../styles/var";
  .the-loading {
    margin-top: auto;
    .alert-box {
      background: #8BB920;
      color: #FFFFFF;
    }
    .loading {
      margin-left: 1rem;
    }
  }
</style>


