<template>
  <header class="flex flex-between">
    <figure>
      <img src="../assets/logo.png" alt="logo">
    </figure>
    <TheLoading :showLoading="pptxLoading" @download-disabled="handleCloseLoading" />
    <div>
      <div class="flex align-center h100">
        <button id="info-button"
                tooltip="email: max.matt@isi-insights.com">
          <v-icon>
            mdi-information-outline
          </v-icon>
        </button>
        <button title="Download PPTX"
                @click="handlePdf">
          <v-icon>
            mdi-cloud-download-outline
          </v-icon>
        </button>
        <button title="Username">
          <v-icon>
            mdi-account-outline
          </v-icon>
          <span>{{user.name}}</span>
        </button>
        <button @click="handleLogout"
                title="Logout">
          <v-icon>
            mdi-logout-variant
          </v-icon>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import TheLoading from '@/components/TheLoading'
export default {
  name: 'TheHeader',
  components: {
    TheLoading
  },
  props: {
    user: Object,
    pptxLoading: Boolean
  },
  data () {
    return {
      showLoading: false
    }
  },
  methods: {
    handleLogout () {
      this.$emit('handle-logout')
    },
    handlePdf () {
      this.showLoading = true
      this.$emit('handle-download-pdf')
    },
    handleCloseLoading (value) {
      this.showLoading = value
    }
  }
}
</script>

<style lang="scss">
  header {
    padding: 4px 16px;
    border-bottom: 1px solid lightgray;
    img {
      height: 80px;
    }
    > div button {
      margin-right: 12px;
    }
    #info-button {
      position: relative;
      &:before {
        content: attr(tooltip);
        display: none;
        position: absolute;
        top: 30px;
        left: -20px;
        white-space: nowrap;
        opacity: 0.8;
        color: white;
        background-color: #363636;
        padding: 3px 5px;
        font-size: 14px;
      }
      &:hover:before {
        display: block;
      }
    }
  }
</style>



