const api = {
  LOGIN: '/api/auth/login',
  LOGOUT: '/api/auth/logout',
  CHECK_LOGIN: '/api/auth/check-login',
  MAIN_MENU: '/api/data/get-content',
  WAVES_DIRECTION: '/api/data/get-directions-by-waves',
  FLAVOURS_DIRECTION: '/api/data/get-flavours-by-directions',
  CHART_BAR_9_POINT: '/api/data/get-computed-data-9-point',
  CHART_5_POINT_SCALE: '/api/data/get-computed-data-5-point',
  FIVE_CHARTS:  '/api/data/get-computed-data-5-charts',
  CHART_JAR_SCALE: '/api/data/get-computed-data-jar',
  CHART_WORD_CLOUD: '/api/data/get-computed-data-wordcloud',
  CHART_KPIS: '/api/data/get-computed-data-kpi',
  CHART_MOTIVES: '/api/data/get-computed-data-motives',
  TABLE_5_POINT_SCALE: '/api/data/get-computed-data-5ps-table',
  TABLE_9_POINT_SCALE: '/api/data/get-computed-data-9ps-table',
  TABLE_9_JAR_A: '/api/data/get-computed-data-jar-table-a',
  TABLE_9_JAR_B: '/api/data/get-computed-data-jar-table-b',
  TABLE_RATINGS: '/api/data/get-computed-data-ratings-table',

  CHART_SPECIFIC_QUESTION: '/api/data/get-computed-data-questions',
  RESULTS: '/api/data/get-data-by-selection',
  ANALYZE: '/api/data/get-computed-data-test',
}

export default api



