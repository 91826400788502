<template>
  <div class="kpis-chart-wrapper flex rows">
    <div class="chart-wrapper">
      <highcharts class="hc" :options="chartOptions1" ref="chart"></highcharts>
    </div>
    <div class="vertical-line"> </div>
    <div class="chart-wrapper">
      <highcharts class="hc" :options="chartOptions2" ref="chart"></highcharts>
    </div>
    <div class="vertical-line"> </div>
    <div class="chart-wrapper">
      <highcharts class="hc" :options="chartOptions3" ref="chart"></highcharts>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChartBar',

    props: {
      chartData: {
        type: Object,
        default: undefined,
        required: true
      }
    },

    mounted() {
      let x = document.getElementsByClassName('kpis-chart-wrapper')[0]
      let vl = document.getElementsByClassName('vertical-line')
      let height = x.getBoundingClientRect().height
      for (let i = 0; i < vl.length; i++) {
        vl[i].style.height = height - 60  + 'px'
      }

      let arr = [[], [], []]
      Object.keys(this.chartData).forEach((key, index) => {
        this.chartData[key].forEach((row, nr) => {
          row.forEach(value => {
            if (nr % 2) arr[index].push('#7C7C7C')
            else arr[index].push('#F07300')
          })
        })
      })

      let series = document.getElementsByClassName('highcharts-series')
      let i, j
      for (i = 0; i < series.length; i++) {
        let bar = series[i].getElementsByClassName('highcharts-point')
        for (j = 0; j < bar.length; j++) {
          bar[j].style.fill = arr[i][j]
        }
      }
    },

    computed: {
      chartOptions1 () {
        return {
          chart: {
            type: 'bar',
            height: this.chartDetails1.barValue.length > 1 ? 90 * this.chartDetails1.barValue.length : 250,
          },
          title: {
            text: 'Overall Liking (Mean)',
            style: {
              fontSize: 26 + 'px',
            }
          },
          subtitle: {
            text: null
          },
          xAxis: {
            categories: this.chartDetails1.barName,
            title: {
              text: null
            },
            labels: {
              style: {
                fontSize: 14 + 'px',
                color: '#595959'
              }
            }
          },
          yAxis: {
            min: 1,
            max: 9,
            // tickInterval: 1,
            visible: false,
            title: {
              enabled: false
            },

          },
          tooltip: {
            valueSuffix: null,
            formatter: function() {
              return `<span class="tooltip-bold"> ${this.x}</span><br>
                      Value: <span class="tooltip-bold">
                        ${this.point.y.toFixed(1).replace('.', ',')}
                      </span>`;
            },
          },
          plotOptions: {
            series: {
              pointWidth: 50,
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: 18 + 'px',
                },
                color: '#595959',
                formatter: function() {
                  return this.y.toFixed(1).replace('.', ',');
                },
              }
            },
            bar: {
              dataLabels: {
                enabled: true
              },
            }
          },
          legend:{ enabled:false },
          credits: {
            enabled: false
          },
          series: [{
            data: this.chartDetails1.barValue,
            color: "#F07300",
          }]
        }
      },
      chartOptions2 () {
        return {
          chart: {
            type: 'bar',
            height: this.chartDetails2.barValue.length > 1 ? 90 * this.chartDetails2.barValue.length : 250,
          },
          title: {
            text: 'Purchase Intention (Top 2 in %)',
            style: {
              fontSize: 26 + 'px'
            }
          },
          subtitle: {
            text: null
          },
          xAxis: {
            categories: this.chartDetails1.barName,
            labels: {
              enabled: false // disable labels
            },
            title: {
              text: null
            },
          },
          yAxis: {
            min: 0,
            max: 100,
            // tickInterval: 10,
            visible: false,
            title: {
              enabled: false
            },

          },
          tooltip: {
            valueSuffix: null,
            formatter: function() {
              return `<span class="tooltip-bold"> ${this.x}</span><br>
                      Value: <span class="tooltip-bold">
                        ${this.point.y}
                      </span>`;
            },
          },
          plotOptions: {
            series: {
              pointWidth: 50,
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: 18 + 'px',
                },
                color: '#595959'
              }
            },
            bar: {
              dataLabels: {
                enabled: true
              },
            }
          },
          legend:{ enabled:false },
          credits: {
            enabled: false
          },
          series: [{
            data: this.chartDetails2.barValue,
            color: "#F07300",
          }]
        }
      },
      chartOptions3 () {
        return {
          chart: {
            type: 'bar',
            height: this.chartDetails3.barValue.length > 1 ? 90 * this.chartDetails3.barValue.length : 250,
          },
          title: {
            text: 'Taste Liking (Mean)',
            style: {
              fontSize: 26 + 'px'
            },

          },
          subtitle: {
            text: null
          },
          xAxis: {
            categories: this.chartDetails1.barName,
            labels: {
              enabled: false // disable labels
            },
            title: {
              text: null
            },
          },
          yAxis: {
            min: 1,
            max: 9,
            tickInterval: 1,
            visible: false,
            title: {
              enabled: false
            },

          },
          tooltip: {
            valueSuffix: null,
            formatter: function() {
              return `<span class="tooltip-bold"> ${this.x}</span><br>
                      Value: <span class="tooltip-bold">
                        ${this.point.y.toFixed(1).replace('.', ',')}
                      </span>`;
            },
          },
          plotOptions: {
            series: {
              pointWidth: 50,
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: 18 + 'px',
                },
                color: '#595959',
                formatter: function() {
                  return this.y.toFixed(1).replace('.', ',');
                },
              }
            },
            bar: {
              dataLabels: {
                enabled: true
              },
            }
          },
          legend:{ enabled:false },
          credits: {
            enabled: false
          },
          series: [{
            data: this.chartDetails3.barValue,
            color: "#F07300",
          }]
        }
      },

      chartDetails1 () {
        let array = { barValue: [], barName: []}
        const analyze = this.chartData.overall_liking
        analyze.forEach(row => {
          row.forEach(value => {
            array.barValue.push(+value.mean)
            array.barName.push(value.name + '<br>' + value.subtitle)
          })
        })
        return array
      },
      chartDetails2 () {
        let array = { barValue: [], barName: []}
        const analyze = this.chartData.purchase_intention
        analyze.forEach(row => {
          row.forEach(value => {
            array.barValue.push(+value.top2)
            array.barName.push(value.name)
          })
        })
        return array
      },
      chartDetails3 () {
        let array = { barValue: [], barName: []}
        const analyze = this.chartData.taste_liking
        analyze.forEach(row => {
          row.forEach(value => {
            array.barValue.push(+value.mean)
            array.barName.push(value.name)
          })
        })
        return array
      },
    },

  }
</script>

<style lang="scss">
  .kpis-chart-wrapper {
    gap: 1rem;
    margin-top: -75px;
    width: 98%;

    .vertical-line {
      background: #ccc;
      width: 1px;
      border-left: solid 1px #ccc;
      margin-top: 50px;
      margin-left: 30px;
    }
    .chart-wrapper {
      width: 100%;
      min-width: 240px;
    }
  }
</style>
