<template>
  <div class="dropdown-select-sort">
    <v-list-group v-model="setDisplayMenu"
                  v-click-outside="onClickOutside">
      <!--      prepend-icon="mdi-account-circle"-->
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>Sort by: {{ sortBy.label }}</v-list-item-title>
        </v-list-item-content>
      </template>
      <div class="dropdown-options">
        <v-list-item
            v-for="(option, i) in sortOptions"
            :key="i"
            @click="handleSelectQuestion(option)"
            :class="{'active-sort' : option.label === sortBy.label}"
        >
          <v-list-item-title v-text="option.label" ></v-list-item-title>
        </v-list-item>
      </div>
    </v-list-group>
  </div>
</template>

<script>
export default {
  name: "DropdownSelect",
  props: {
    sortOptions: Array,
    sortBy: Object,
  },
  data () {
    return {
      setDisplayMenu: false,
    }
  },
  computed: {
    getActiveSortBy () {
      return this.$route.query
    }
  },
  methods: {
    handleSelectQuestion (item) {
      this.setDisplayMenu = false
      this.$emit('handle-select-sort-by', {
        item,
        sortingArray: this.$route.query.section
      })
    },

    onClickOutside () {
      this.setDisplayMenu = false
    }
  },

  // watch: {
    // sortOptions: {
    //   handler(arr) {
    //     this.handleSelectQuestion(this.sortBy)
    //   }, immediate: true
    // },
  // }
}
</script>

<style lang="scss">
@import "../styles/var";


.dropdown-select-sort {
  min-width: 150px;
  width: 275px;
  position: relative;
  .active-sort {
    background: rgba(255, 237, 220, 0.82);
    box-shadow: 3px 0 0 0 $primary inset;
  }
  .dropdown-options {
    z-index: 99999;
    position: absolute;
    background: #FFFFFF;
    width: 100%;
    border: solid 1px #ccc;
  }
}
</style>



