var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-9-point-scale"},[_c('div',{staticClass:"flex columns align-center justify-center"},[_c('table',[_vm._m(0),_c('tbody',_vm._l((_vm.chartData),function(row){return _c('tr',[_c('td',{staticClass:"row-header",domProps:{"innerHTML":_vm._s(row[0] + '<br/>' + row[21])}}),_c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex flex-between"},[_c('span',{class:_vm.minMaxValues.q1.length > 1 && Math.min.apply(Math, _vm.minMaxValues.q1) === row[1] ? 'bg-red' :
                        _vm.minMaxValues.q1.length > 1 && Math.max.apply(Math, _vm.minMaxValues.q1) === row[1] ? 'bg-green' : ''},[_vm._v(_vm._s(row[1].toFixed(1).replace('.', ','))+" ")]),_c('span',[_vm._v(_vm._s(row[2]))]),_c('span',[_vm._v(_vm._s(row[3]))]),_c('span',[_vm._v(_vm._s(row[4]))])])]),_c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex flex-between"},[_c('span',{class:_vm.minMaxValues.q2.length > 1 && Math.min.apply(Math, _vm.minMaxValues.q2) === row[5] ? 'bg-red' :
                        _vm.minMaxValues.q2.length > 1 && Math.max.apply(Math, _vm.minMaxValues.q2) === row[5] ? 'bg-green' : ''},[_vm._v(_vm._s(row[5].toFixed(1).replace('.', ','))+" ")]),_c('span',[_vm._v(_vm._s(row[6]))]),_c('span',[_vm._v(_vm._s(row[7]))]),_c('span',[_vm._v(_vm._s(row[8]))])])]),_c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex flex-between"},[_c('span',{class:_vm.minMaxValues.q3.length > 1 && Math.min.apply(Math, _vm.minMaxValues.q3) === row[9] ? 'bg-red' :
                        _vm.minMaxValues.q3.length > 1 && Math.max.apply(Math, _vm.minMaxValues.q3) === row[9] ? 'bg-green' : ''},[_vm._v(_vm._s(row[9].toFixed(1).replace('.', ','))+" ")]),_c('span',[_vm._v(_vm._s(row[10]))]),_c('span',[_vm._v(_vm._s(row[11]))]),_c('span',[_vm._v(_vm._s(row[12]))])])]),_c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex flex-between"},[_c('span',{class:_vm.minMaxValues.q4.length > 1 && Math.min.apply(Math, _vm.minMaxValues.q4) === row[13] ? 'bg-red' :
                        _vm.minMaxValues.q4.length > 1 && Math.max.apply(Math, _vm.minMaxValues.q4) === row[13] ? 'bg-green' : ''},[_vm._v(_vm._s(row[13].toFixed(1).replace('.', ','))+" ")]),_c('span',[_vm._v(_vm._s(row[14]))]),_c('span',[_vm._v(_vm._s(row[15]))]),_c('span',[_vm._v(_vm._s(row[16]))])])]),_c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex flex-between"},[_c('span',{class:_vm.minMaxValues.q5.length > 1 && Math.min.apply(Math, _vm.minMaxValues.q5) === row[17] ? 'bg-red' :
                        _vm.minMaxValues.q5.length > 1 && Math.max.apply(Math, _vm.minMaxValues.q5) === row[17] ? 'bg-green' : ''},[_vm._v(_vm._s(row[17].toFixed(1).replace('.', ','))+" ")]),_c('span',[_vm._v(_vm._s(row[18]))]),_c('span',[_vm._v(_vm._s(row[19]))]),_c('span',[_vm._v(_vm._s(row[20]))])])])])}),0)]),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"table-bg"}),_c('th',{staticClass:"table-bg",attrs:{"colspan":"4"}},[_c('div',[_c('p',[_vm._v("Overall Liking")])])]),_c('th',{staticClass:"table-bg",attrs:{"colspan":"4"}},[_c('div',[_c('p',[_vm._v("Taste Liking")])])]),_c('th',{staticClass:"table-bg",attrs:{"colspan":"4"}},[_c('div',[_c('p',[_vm._v("Naturalness")])])]),_c('th',{staticClass:"table-bg",attrs:{"colspan":"4"}},[_c('div',[_c('p',[_vm._v("Aftertaste Liking")])])]),_c('th',{staticClass:"table-bg",attrs:{"colspan":"4"}},[_c('div',[_c('p',[_vm._v("Aroma Liking")])])])]),_c('tr',[_c('th',{staticClass:"table-bg"}),_c('th',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex flex-between align-center"},[_c('span',[_vm._v("Mean")]),_c('span',[_vm._v("Sign")]),_c('span',[_vm._v("T3%")]),_c('span',[_vm._v("L3%")])])]),_c('th',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex flex-between align-center"},[_c('span',[_vm._v("Mean")]),_c('span',[_vm._v("Sign")]),_c('span',[_vm._v("T3%")]),_c('span',[_vm._v("L3%")])])]),_c('th',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex flex-between align-center"},[_c('span',[_vm._v("Mean")]),_c('span',[_vm._v("Sign")]),_c('span',[_vm._v("T3%")]),_c('span',[_vm._v("L3%")])])]),_c('th',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex flex-between align-center"},[_c('span',[_vm._v("Mean")]),_c('span',[_vm._v("Sign")]),_c('span',[_vm._v("T3%")]),_c('span',[_vm._v("L3%")])])]),_c('th',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex flex-between align-center"},[_c('span',[_vm._v("Mean")]),_c('span',[_vm._v("Sign")]),_c('span',[_vm._v("T3%")]),_c('span',[_vm._v("L3%")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-description"},[_c('p',[_vm._v(" Mean | Sign. | in %")]),_c('p',[_vm._v("Explanation: Significant differences between products are indicated by different letters (A≠B), (p≤0.10). Letter A states the higher value "),_c('span',{staticClass:"green"},[_vm._v("Green")]),_vm._v(" for best prototype / concept of selection; "),_c('span',{staticClass:"red"},[_vm._v("Red")]),_vm._v(" for worst prototype / concept of selection; "),_c('span',{staticClass:"white"},[_vm._v("White")]),_vm._v(" all others. ")])])
}]

export { render, staticRenderFns }