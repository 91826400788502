<template>
  <div class="stacked-chart-wrapper">
    <div class="chart-questions">
      <h3 class="title">{{ chartData.Question }}</h3>
      <p >{{ chartData.ScaleDescription }}</p>
    </div>
    <div class="flex columns align-center justify-center">
      <div class="flex-wrapper flex">
        <div class="chart-container">
          <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
        </div>
        <div>
        <table>
            <thead>
            <tr>
              <th>{{ evaluation === 'expectation' ? 'Top3' : 'Top2' }}<br />In %</th>
              <th>{{ evaluation === 'expectation' ? 'Top3' : 'Top2' }}<br />Sign.</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="data in chartData.rows">
              <td>{{ evaluation === 'expectation' ? data.top3 : data.top2 }}</td>
              <td>{{ evaluation === 'expectation' ? data.top3Sign : data.top2Sign }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="general-description">
        <p> in % | {{ evaluation === 'expectation' ? 'Top3' : 'Top2' }} in % | Sig.</p>
        <p>Explanation: Significant differences between products are indicated by different letters (A≠B), (p≤0.10). Letter A states the higher value.</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ChartStackedBar",
    props: {
      chartData: {
        type: Object,
        default: undefined,
        required: true,
      },
      evaluation: {
        type: String,
        default: undefined,
        required: true,
      },
      bases: String
    },
    mounted () {
      // const textAxis = document.getElementsByClassName('highcharts-axis-labels highcharts-xaxis-labels')[0]
      // const legend = document.getElementsByClassName('highcharts-legend highcharts-no-tooltip')
      // legend[1].style.left = textAxis.getBoundingClientRect().width + 12 + 'px'
    },
    computed: {
      chartOptions () {
        return {
          chart: {
            type: 'bar',
            height: this.chartData.rows.length > 2 ? 85 * this.chartData.rows.length : 200
          },
          credits: {
            enabled: false
          },
          title: {
            text: ''
            // text: this.chartData.Header,
            // style: {
            //   fontSize: 26    + 'px'
            // }
          },
          xAxis: {
            categories: this.chartDetails.barName,
            labels: {
              style: {
                fontSize: 18 + 'px',
                color: '#595959'
              }
            }
          },
          yAxis: {
            // min: 0,
            max: 100,
            // tickInterval: 20,
            visible: false,
            title: {
              enabled: false
            }
          },
          tooltip: {
            formatter: function() {
              return 'The value for <b>'+ this.x +
                  '</b> is <b>'+ this.y +'%</b>';
            }
          },
          legend: {
            reversed: true,
            symbolHeight: .001,
            symbolWidth: .001,
            useHTML: true,
            margin: 0,
            labelFormatter: function () {
              return '<div class="hc-legend" style="background-color:'+this.color+';">'+ this.name +'</div>';
            }
          },
          plotOptions: {
            series: {
              stacking: 'percent',
              pointWidth: 45,
              dataLabels: {
                enabled: true,
                inside: true,
                style: {
                  fontSize: 20 + 'px',
                  color: "#fff"
                }
              }
            },
          },
          series: this.chartBarsValue
        }
      },

      chartDetails () {
        let array = { barName: []}
        const analyze = this.chartData
        if (['appealing', 'purchase_intention_concept', 'edition', 'uniqueness'].includes(this.evaluation)) {
          analyze.rows.forEach(value => {
            array.barName.push(value.name)
          })
        } else {
          analyze.rows.forEach(value => {
            array.barName.push(value.name + '<br>' + value.subtitle)
          })
        }
        return array
      },

      chartBarsValue () {
        let obj = []
        this.chartData.rows.forEach(row => {
          let arr = { data: undefined}
          arr.data = Object.keys(row.percentages).map((key) => [Math.round(row.percentages[key])])
          arr.data = arr.data.flat(2)
          obj.push(arr)
        })
        let series = undefined
        if (this.chartData.Header === 'Ranking') {
          series = [{data: [], index: 5, color: "#783A00", name: "Rank 5"},
            {data: [], index: 4, color: "#B45600", name: "Rank 4"},
            {data: [], index: 3, color: "#7C7C7C", name: "Rank 3"},
            {data: [], index: 2, color: "#8BB920", name: "Rank 2"},
            {data: [], index: 1, color: "#455D10", name: "Rank 1"}]
        } else {
          series = [{data: [], index: 5, color: "#783A00", name: "--"},
            {data: [], index: 4, color: "#B45600", name: "-"},
            {data: [], index: 3, color: "#7C7C7C", name: "0"},
            {data: [], index: 2, color: "#8BB920", name: "+"},
            {data: [], index: 1, color: "#455D10", name: "++"}]
        }
        obj.forEach(elem => {
          elem.data.forEach((val, index )=> {
            series[index].data.push(val)
          })
        })
        return series
      }
    }
  }
</script>

<style lang="scss">
  .stacked-chart-wrapper {
    margin: 50px 0 0 0;
    .highcharts-legend-item-hidden {
      opacity: 0.4 !important;
    }
    .flex-wrapper {
      gap: 2rem;
      .chart-container {
        width: 1150px;
        min-width: 300px;
      }
      table {
        height: calc(100% - 40px);
        margin-right: 4rem;
        margin-top: -30px;
        thead tr th {
          height: 30px;
          font-weight: unset;
        }
        tbody tr td {
          text-align: center;
          width: 100px;
        }
      }
    }

    .hc-legend {
      width: 78px;
      font-size: 1.15rem;
      border-radius: 7px;
      color: #fff;
      text-align: center;
      padding: 8px 0;
      margin: 0;
    }
  }


</style>
