<template>
  <div class="word-cloud-chart-wrapper flex columns align-center justify-center">
    <div class="flex justify-center align-center gap-32">
      <div class="flex-wrapper flex flex-center columns">
        <p class="title-word-cloud">{{ chartData.name }}</p>
        <div class="chart-container">
          <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
        </div>
        <div class="general-description">
          <p>Explanation: The size of the specific answer implies on number of mentions</p>
        </div>
      </div>
      <table>
        <thead>
        <tr>
          <th>Description Top 15</th>
          <th>Count</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value, key , index) in chartData.words" v-if="index < 15">
          <td>{{ key }}</td>
          <td>{{ value }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ChartWordCloud",
    props: {
      chartData: {
        type: Object,
        default: undefined,
        required: true
      },
      question: String,
      bases: String
    },
    data () {
      return {
        greenPalette: ['#489A1E', '#79D738','#99FF99','#145D04','#9BD138'],
        redPalette: ['#9D1C2B', '#B91F26','#DA2B2B','#F26764','#F7B1AE'],
        bluePalette: ['#03045E', '#00B4D8','#90E0EF','#CAF0F8'],
      }
    },
    computed: {
      chartOptions () {
        return {
          chart: {
            type: 'wordcloud',
            height: 700
          },
          credits: {
            enabled: false
          },
          title: {
            text: '',
          },
          plotOptions: {
            series: {
              rotation: {
                from: 0,
                to: 0
              },
              name: 'Occurrences',
              spiral: 'archimedean',
              placementStrategy: 'center',
              allowExtendPlayingField: true,
              minFontSize: 50,
              maxFontSize: 250,

              colors: this.question === 'Flavour Description' ? this.bluePalette :
                      this.question === 'Likes' ? this.greenPalette : this.redPalette,
              shadow: false,
            },

          },
          series: [{
            data: this.wordCloudData,
          }],
        }
      },

      wordCloudData () {
        let data = []
        for (const [key, value] of Object.entries( this.chartData.words)) {
          data.push({ name: key, weight: value })
        }
        return data
      }
    }
  }
</script>

<style lang="scss">
  .word-cloud-chart-wrapper {
    margin: 50px 0 0 0;
    width: 100%;
    .flex-wrapper {
      gap: 2rem;
      position: relative;
      .general-description {
        margin-top: -25px;
      }
    }

    table {
      margin-top: -60px;
      max-width: 275px;
      thead tr {
        background: #826E5F;
        color: #FFFFFF;
      }
      tbody tr:nth-child(even) {
        background: #EDEBEA;
      }
      tbody tr {
        background: #D8D5D2;
      }
      tr th, tr td {
        padding: 0.43rem 1rem;
        text-align: center;
        height: 42px;
      }
      tbody tr td:nth-child(1) {
        max-width: 35ch;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  @media screen and (min-width: 0) {
    .word-cloud-chart-wrapper {
      .title-word-cloud {
        position: absolute;
        transform: rotate(270deg) translateY(-275px);
      }
      .chart-container {
        width: 500px;
        border: solid 1px #ccc;
      }
    }
  }
  @media screen and (min-width: 1550px) {
    .word-cloud-chart-wrapper {
      .title-word-cloud {
        transform: rotate(270deg) translateY(-375px);
      }
      .chart-container {
        width: 700px;
      }
    }
  }
  @media screen and (min-width: 1750px) {
    .word-cloud-chart-wrapper {
      .title-word-cloud {
        transform: rotate(270deg) translateY(-450px);
      }
      .chart-container {
        width: 850px;
      }
    }
  }
  @media screen and (min-width: 1920px) {
    .word-cloud-chart-wrapper {
      .title-word-cloud {
        transform: rotate(270deg) translateY(-525px);
      }
      .chart-container {
        width: 1000px;
      }
    }
  }
</style>



