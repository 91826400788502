<template>
  <div class="table-jar">
    <div class="flex columns align-center justify-center">
      <table>
        <thead>
        <tr v-if="chartType === 'CTTableJARA'">
          <th class="table-bg"></th>
          <th colspan="5" class="table-bg"><div><p>[Flavour specific q. ]</p></div></th>
          <th colspan="5" class="table-bg"><div><p>[Flavour specific q. ]</p></div></th>
          <th colspan="5" class="table-bg"><div><p>[Flavour specific q. ]</p></div></th>
          <th colspan="5" class="table-bg"><div><p>Aroma Rating</p></div></th>
          <th colspan="5" class="table-bg"><div><p>Amount</p></div></th>
          <th colspan="5" class="table-bg"><div><p>Aftertaste Intensity</p></div></th>
        </tr>
        <tr v-if="chartType === 'CTTableJARB'">
          <th class="table-bg"></th>
          <th colspan="5" class="table-bg"><div><p>Taste Intensity</p></div></th>
          <th colspan="5" class="table-bg"><div><p>Sweetness</p></div></th>
          <th colspan="5" class="table-bg"><div><p>Main Taste Intensity</p></div></th>
          <th colspan="5" class="table-bg"><div><p>Throat Hit</p></div></th>
          <th colspan="5" class="table-bg"><div><p>Nicotine Hit</p></div></th>
        </tr>
        <tr>
          <th class="table-bg"></th>
          <th colspan="5">
            <div class="flex flex-between align-center">
              <span>L2%</span><span>JAR</span><span>T2%</span><span>Sig.</span><span>pen-<br>alty</span>
            </div>
          </th>
          <th colspan="5">
            <div class="flex flex-between align-center">
              <span>L2%</span><span>JAR</span><span>T2%</span><span>Sig.</span><span>pen-<br>alty</span>
            </div>
          </th>
          <th colspan="5">
            <div class="flex flex-between align-center">
              <span>L2%</span><span>JAR</span><span>T2%</span><span>Sig.</span><span>pen-<br>alty</span>
            </div>
          </th>
          <th colspan="5">
            <div class="flex flex-between align-center">
              <span>L2%</span><span>JAR</span><span>T2%</span><span>Sig.</span><span>pen-<br>alty</span>
            </div>
          </th>
          <th colspan="5">
            <div class="flex flex-between align-center">
              <span>L2%</span><span>JAR</span><span>T2%</span><span>Sig.</span><span>pen-<br>alty</span>
            </div>
          </th>
          <th v-if="chartType === 'CTTableJARA'" colspan="5">
            <div class="flex flex-between align-center">
              <span>L2%</span><span>JAR</span><span>T2%</span><span>Sig.</span><span>pen-<br>alty</span>
            </div>
          </th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="row in tableData">
          <td class="row-header" v-html="row[0] + '<br/>' + (row[37] ? row[37] : row[31])"></td>
          <td colspan="5" >
            <div class="flex flex-between">
              <span>{{ row[1] }}</span>
              <span>{{ row[2] }}</span>
              <span>{{ row[3] }}</span>
              <span>{{ row[4] }}</span>
              <span :class="`bg-${row[6]}`">{{ row[6] === 'green' || row[6] === '' ? '' : row[5].replace('.', ',') }}</span>
            </div>
          </td>
          <td colspan="5" >
            <div class="flex flex-between">
              <span>{{ row[7] }}</span>
              <span>{{ row[8] }}</span>
              <span>{{ row[9] }}</span>
              <span>{{ row[10] }}</span>
              <span :class="`bg-${row[12]}`">{{ row[12] === 'green' || row[12] === '' ? '' :  row[11].replace('.', ',') }}</span>
            </div>
          </td>
          <td colspan="5" >
            <div class="flex flex-between">
              <span>{{ row[13] }}</span>
              <span>{{ row[14] }}</span>
              <span>{{ row[15] }}</span>
              <span>{{ row[16] }}</span>
              <span :class="`bg-${row[18]}`">{{ row[18] === 'green' || row[18] === '' ? '' :  row[17].replace('.', ',') }}</span>
            </div>
          </td>
          <td colspan="5" >
            <div class="flex flex-between">
              <span>{{ row[19] }}</span>
              <span>{{ row[20] }}</span>
              <span>{{ row[21] }}</span>
              <span>{{ row[22] }}</span>
              <span :class="`bg-${row[24]}`">{{ row[24] === 'green' || row[24] === '' ? '' :  row[23].replace('.', ',') }}</span>
            </div>
          </td>
          <td colspan="5" >
            <div class="flex flex-between">
              <span>{{ row[25] }}</span>
              <span>{{ row[26] }}</span>
              <span>{{ row[27] }}</span>
              <span>{{ row[28] }}</span>
              <span :class="`bg-${row[30]}`">{{ row[30] === 'green' || row[30] === '' ? '' :  row[29].replace('.', ',') }}</span>
            </div>
          </td>
          <td colspan="5" v-if="chartType === 'CTTableJARA'">
            <div class="flex flex-between">
              <span>{{ row[31] }}</span>
              <span>{{ row[32] }}</span>
              <span>{{ row[33] }}</span>
              <span>{{ row[34] }}</span>
              <span :class="`bg-${row[36]}`">{{ row[36] === 'green' || row[36] === '' ? '' :  row[35].replace('.', ',') }}</span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="general-description">
        <p> in % | JAR Sig. | Penalty</p>
        <p>(5-point-scale: ++ // + // 0 // - // -- )</p>
        <p>
          <span class="green">Green</span> if skews ≤ 15% and JAR ≥ 66% || skews ≥ 15% and JAR ≥ 66% and Penalty < 0.2; <span class="yellow">Yellow</span> if skews ≥ 15% and Penalty ≥ 0.2 BUT ≤ 0.6; <span class="red">Red</span> if skews ≥ 15% and Penalty  ≥  0.6; White all others.
        </p>
<!--        <p>-->
<!--          Explanation: Significant differences between products are indicated by different letters (A≠B), (p≤0.10). Letter A states the higher value-->
<!--          Remark: Penalties on overall opinion are highlighted for skews ≥ 15% and Penalty ≥ 0.2-->
<!--          <span class="green">Green</span> if Penalty ≤ 0.2 and skews ≤ 15%; <span class="yellow">Yellow</span> if Penalty ≥ 0.2 BUT ≤ 0.6; <span class="red">Red</span> if Penalty > 0.6; White all others.-->
<!--        </p>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Table5Jar',
  props: {
    chartData: Array,
    chartType: String,
    bases: String
  },
  computed: {
    tableData () {
      let array = this.chartData
      array.forEach((row, index) => {
        row.forEach((value, no) => {
          if (value === 0) array[index][no] = ''
        })
      })
      return array
    }
  }
}
</script>

<style lang="scss">
.table-jar {
  margin-top: 50px;
  .table-bg {
    background: rgb(49, 47, 47);
    color: #FFFFFF;
  }
  table, th, td {
    border: 1px solid rgb(49, 47, 47);
    border-collapse: collapse;
  }
  table {
    .bg-green {
      background: #8BB920;
    }
    .bg-yellow {
      background: #FFC000;
    }
    .bg-red {
      background: #9D1C2B;
      color: #FFFFFF;
    }
    tr th {
      height: 50px;
      min-width: 150px;
      width: 225px;
      div {
        height: 100%;
        width: 100%;
        border-left: solid 1px #fff;
        p {
          font-size: 18px;
          padding: 14px 0;
        }
        span {
          width: 20%;
          text-align: center;
        }
      }
    }

    tr td {
      height: 75px;
    }
    tbody {
      tr td:nth-child(1) {
        text-align: right;
        padding-right: 1rem;
      }
      tr td div {
        height: 100%;
        width: 100%;
        span {
          width: 20%;
          text-align: center;
          padding-top: 28px;
        }
      }
    }
  }
}
</style>
