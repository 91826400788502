<template>
  <div class="table-ratings">
    <div class="flex columns align-center justify-center">
      <table>
        <thead>
        <tr>
          <th class="table-bg"></th>
          <th colspan="3" class="table-bg"><div><p>Purchase Intention</p></div></th>
          <th colspan="3" class="table-bg"><div><p>Expectations</p></div></th>
          <th colspan="3" class="table-bg"><div><p>Ranking</p></div></th>
        </tr>
        <tr>
          <th class="table-bg"></th>
          <th colspan="3">
            <div class="flex flex-between align-center">
              <span>T2%</span><span>Sig.</span><span>L2%</span>
            </div>
          </th>
          <th colspan="3">
            <div class="flex flex-between align-center">
              <span>T3%</span><span>Sig.</span><span>L2%</span>
            </div>
          </th>
          <th colspan="3">
            <div class="flex flex-between align-center">
              <span>T2%</span><span>Sig.</span><span>L2%</span>
            </div>
          </th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="row in chartData">
          <td class="row-header" v-html="row[0] + '<br/>' + row[10]"></td>
          <td colspan="3" >
            <div class="flex flex-between">
              <span :class="minMaxValues.q11.length > 1 && Math.min.apply(Math, minMaxValues.q11) === row[1] ? 'bg-red' :
                          minMaxValues.q11.length > 1 && Math.max.apply(Math, minMaxValues.q11) === row[1] ? 'bg-green' : ''">{{ row[1] }}
              </span>
              <span>{{ row[2] }}</span>
              <span >{{ row[3] }}</span>
            </div>
          </td>
          <td colspan="3">
            <div class="flex flex-between">
              <span :class="minMaxValues.q21.length > 1 && Math.min.apply(Math, minMaxValues.q21) === row[4] ? 'bg-red' :
                          minMaxValues.q21.length > 1 && Math.max.apply(Math, minMaxValues.q21) === row[4] ? 'bg-green' : ''">{{ row[4] }}
              </span>
              <span>{{ row[5] }}</span>
              <span>{{ row[6] }}</span>
            </div>
          </td>
          <td colspan="3">
            <div class="flex flex-between">
              <span :class="minMaxValues.q31.length > 1 && Math.min.apply(Math, minMaxValues.q31) === row[7] ? 'bg-red' :
                          minMaxValues.q31.length > 1 && Math.max.apply(Math, minMaxValues.q31) === row[7] ? 'bg-green' : ''">{{ row[7] }}
              </span>
              <span>{{ row[8] }}</span>
              <span>{{ row[9] }}</span>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="general-description">
        <p> Mean | Sign. | in %</p>
        <p>(5-point-scale: ++ // + // 0 // - // -- )</p>
        <p>
          Explanation: Significant differences between products are indicated by different letters (A≠B), (p≤0.10). Letter A states the higher value
          <span class="green">Green</span> for best prototype / concept of selection; <span class="red">Red</span> for worst prototype / concept of selection; <span class="white">White</span> all others.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TableRatings',
    props: {
      chartData: Array,
      bases: String
    },
    computed: {
      minMaxValues() {
        let obj = {q11: [], q12: [], q21: [], q22: [], q31: [], q32: []}
        this.chartData.forEach(row => {
          obj.q11.push(row[1])
          obj.q12.push(row[3])
          obj.q21.push(row[4])
          obj.q22.push(row[6])
          obj.q31.push(row[7])
          obj.q32.push(row[9])
        })
        return obj
      }
    }
  }
</script>

<style lang="scss">
.table-ratings {
  margin-top: 50px;
  .table-bg {
    background: rgb(49, 47, 47);
    color: #FFFFFF;
  }
  table, th, td {
    border: 1px solid rgb(49, 47, 47);
    border-collapse: collapse;
  }
  table {
    tr th {
      height: 50px;
      min-width: 150px;
      width: 275px;
      div {
        height: 100%;
        width: 100%;
        border-left: solid 1px #fff;
        p {
          font-size: 18px;
          padding: 14px 0;
        }
        span {
          width: 25%;
          text-align: center;
        }
      }
    }
    tr td {
      height: 75px;
    }
    tbody {
      tr td:nth-child(1) {
        text-align: right;
        padding-right: 1rem;
      }
      tr td div {
        height: 100%;
        width: 100%;
        span {
          width: 25%;
          text-align: center;
          padding-top: 28px;
        }
      }
    }
  }
}
</style>




