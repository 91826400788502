<template>
  <div class="motives-chart-wrapper">
    <div class="chart-wrapper flex ">
      <div class="chart-container">
        <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
      </div>
<!--      <div>-->
<!--        <table>-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>Sign.<br /> Mean</th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr v-for="data in chartDetails.barName">-->
<!--            <td>A</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>
  export default {
    name: 'ChartMotives',

    props: {
      chartData: {
        type: Object,
        default: undefined,
        required: true
      },
    },

    computed: {
      chartOptions () {
        return {
          chart: {
            type: 'bar',
            height: this.chartDetails.barName.length * 40,
          },
          credits: {
            enabled: false
          },
          title: {
            // text: ''
            text: this.chartData.name,
            style: {
              fontSize: 26 + 'px'
            },
            align: 'left'
          },
          subtitle: {
            text: null
          },
          xAxis: {
            categories: this.chartDetails.barName,
            title: {
              text: null
            },
            labels: {
              style: {
                fontSize: 16 + 'px',
                color: '#595959'
              }
            }
          },
          yAxis: {
            // min: 0,
            // max: 100,
            // tickInterval: 20,
            visible: false,
            title: {
              enabled: false
            },
          },
          tooltip: {
            valueSuffix: null
          },
          plotOptions: {
            series: {
              pointWidth: 20,

              dataLabels: {
                enabled: true,
                style: {
                  fontSize: 16 + 'px',
                }
              }
            },
            bar: {
              dataLabels: {
                enabled: true
              },
            }
          },
          legend:{ enabled:false },

          series: [{
            data: this.chartDetails.barValue,
            color: "#F07300",
          }]
        }
      },

      chartDetails () {
        let array = { barValue: [], barName: []}
        for (const [key, value] of Object.entries(this.chartData.percentages)) {
          array.barName.push(key)
          array.barValue.push(Math.round(+value))
        }
        return array
      }
    },

  }
</script>

<style lang="scss">
  .motives-chart-wrapper {
    gap: 2rem;
    margin-top: 50px;
    margin-left: 75px;
    .chart-container {
      width: 750px;
      min-width: 300px;
    }
    //table {
    //  margin-top: 15px;
    //  width: 60px;
    //  height: calc(100% - 30px);
    //  thead tr th {
    //    font-weight: unset;
    //  }
    //  tbody tr td {
    //    height: 25px;
    //    text-align: center;
    //  }
    //}
  }
</style>
