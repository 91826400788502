<template>
  <div class="bar-chart-wrapper">
    <div class="chart-questions">
      <h3 class="title">{{ chartData.Question }}</h3>
      <p >{{ chartData.ScaleDescription }}</p>
    </div>
    <div class="flex columns align-center justify-center">
      <div class="chart-wrapper flex justify-center">
        <div class="chart-container">
          <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Mean<br /> Sig.</th>
                <th>Top3<br /> Sig.</th>
                <th>Low3</th>
                <th>Top3</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in chartData.rows">
                <td>{{ data.meanSign }}</td>
                <td>{{ data.top3Sign }}</td>
                <td>{{ data.low3 }}</td>
                <td>{{ data.top3 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="general-description">
        <p> Mean | Low3/Top3 in % | Sig.</p>
        <p>Explanation: Significant differences between products are indicated by different letters (A≠B), (p≤0.10). Letter A states the higher value.</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChartBar',

    props: {
      chartData: {
        type: Object,
        default: undefined,
        required: true
      },
      bases: String
    },

    computed: {
      chartOptions () {
        return {
          chart: {
            type: 'bar',
            height: this.chartDetails.barValue.length > 2 ? 75 * this.chartDetails.barValue.length :
                this.chartDetails.barValue.length === 2 ? 170 : 100,
          },

          title: {
            text: ''
            // text: this.chartData.Header,
            // style: {
            //   fontSize: 26 + 'px'
            // }
          },
          subtitle: {
            text: null
          },
          xAxis: {
            categories: this.chartDetails.barName,
            title: {
              text: null
            },
            labels: {
              style: {
                fontSize: 18 + 'px',
                color: '#595959'
              },
            }

          },
          yAxis: {
            min: 1,
            max: 9,
            tickInterval: 1,
            title: {
              enabled: false
            },
          },
          tooltip: {
            valueSuffix: null,
            formatter: function() {
              return `<span class="tooltip-bold"> ${this.x}</span><br>
                      Value: <span class="tooltip-bold">
                        ${this.point.y.toFixed(1).replace('.', ',')}
                      </span>`;
            },
          },
          plotOptions: {
            series: {
              pointWidth: 45,

              dataLabels: {
                enabled: true,
                style: {
                  fontSize: 18 + 'px',
                },
                formatter: function() {
                  return this.y.toFixed(1).replace('.', ',');
                },
              }
            },
            bar: {
              dataLabels: {
                enabled: true
              },
            }
          },
          legend:{ enabled:false },
          credits: {
            enabled: false
          },
          series: [{
            data: this.chartDetails.barValue,
            color: "#F07300",
          }]
        }
      },

      chartDetails () {
        let array = { barValue: [], barName: []}
        const analyze = this.chartData
        analyze.rows.forEach(value => {
          array.barValue.push(+value.mean)
          array.barName.push(value.name + '<br>' + value.subtitle)
        })
        return array
      }
    },

  }
</script>

<style lang="scss">
  .chart-wrapper {
    gap: 2rem;
    margin-top: 50px;
    .chart-container {
      width: 1150px;
      min-width: 300px;
    }
    table {
      margin-top: -60px;
      width: 260px;
      height: 100%;
      margin-right: 4rem;
      thead tr th {
        font-weight: unset;
      }
      tr {
        height: 65px;
      }
      tbody tr td {
        height: 45px;
        text-align: center;
      }
    }
  }
</style>




