var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-5-point-scale"},[_c('div',{staticClass:"flex columns align-center justify-center"},[_c('table',[_vm._m(0),_c('tbody',_vm._l((_vm.chartData),function(row){return _c('tr',[_c('td',{staticClass:"row-header"},[_vm._v(_vm._s(row[0]))]),_c('td',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"flex flex-between"},[_c('span',{class:_vm.minMaxValues.q1.length > 1 && Math.min.apply(Math, _vm.minMaxValues.q1) === row[1] ? 'bg-red' :
                          _vm.minMaxValues.q1.length > 1 && Math.max.apply(Math, _vm.minMaxValues.q1) === row[1] ? 'bg-green' : ''},[_vm._v(_vm._s(row[1])+" ")]),_c('span',[_vm._v(_vm._s(row[2]))]),_c('span',[_vm._v(_vm._s(row[3]))])])]),_c('td',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"flex flex-between"},[_c('span',{class:_vm.minMaxValues.q2.length > 1 && Math.min.apply(Math, _vm.minMaxValues.q2) === row[4] ? 'bg-red' :
                          _vm.minMaxValues.q2.length > 1 && Math.max.apply(Math, _vm.minMaxValues.q2) === row[4] ? 'bg-green' : ''},[_vm._v(_vm._s(row[4])+" ")]),_c('span',[_vm._v(_vm._s(row[5]))]),_c('span',[_vm._v(_vm._s(row[6]))])])]),_c('td',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"flex flex-between"},[_c('span',{class:_vm.minMaxValues.q3.length > 1 && Math.min.apply(Math, _vm.minMaxValues.q3) === row[7] ? 'bg-red' :
                          _vm.minMaxValues.q3.length > 1 && Math.max.apply(Math, _vm.minMaxValues.q3) === row[7] ? 'bg-green' : ''},[_vm._v(_vm._s(row[7])+" ")]),_c('span',[_vm._v(_vm._s(row[8]))]),_c('span',[_vm._v(_vm._s(row[9]))])])]),_c('td',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"flex flex-between"},[_c('span',{class:_vm.minMaxValues.q4.length > 1 && Math.min.apply(Math, _vm.minMaxValues.q4) === row[10] ? 'bg-red' :
                          _vm.minMaxValues.q4.length > 1 && Math.max.apply(Math, _vm.minMaxValues.q4) === row[10] ? 'bg-green' : ''},[_vm._v(_vm._s(row[10])+" ")]),_c('span',[_vm._v(_vm._s(row[11]))]),_c('span',[_vm._v(_vm._s(row[12]))])])])])}),0)]),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"table-bg"}),_c('th',{staticClass:"table-bg",attrs:{"colspan":"3"}},[_c('div',[_c('p',[_vm._v("C. Spontaneous Appeal")])])]),_c('th',{staticClass:"table-bg",attrs:{"colspan":"3"}},[_c('div',[_c('p',[_vm._v("C. Purchase Intention")])])]),_c('th',{staticClass:"table-bg",attrs:{"colspan":"3"}},[_c('div',[_c('p',[_vm._v("C. Fit to Limited Edition")])])]),_c('th',{staticClass:"table-bg",attrs:{"colspan":"3"}},[_c('div',[_c('p',[_vm._v("C. Uniqueness")])])])]),_c('tr',[_c('th',{staticClass:"table-bg"}),_c('th',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"flex flex-between align-center"},[_c('span',[_vm._v("T2%")]),_c('span',[_vm._v("Sig.")]),_c('span',[_vm._v("L2%")])])]),_c('th',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"flex flex-between align-center"},[_c('span',[_vm._v("T2%")]),_c('span',[_vm._v("Sig.")]),_c('span',[_vm._v("L2%")])])]),_c('th',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"flex flex-between align-center"},[_c('span',[_vm._v("T2%")]),_c('span',[_vm._v("Sig.")]),_c('span',[_vm._v("L2%")])])]),_c('th',{attrs:{"colspan":"3"}},[_c('div',{staticClass:"flex flex-between align-center"},[_c('span',[_vm._v("T2%")]),_c('span',[_vm._v("Sig.")]),_c('span',[_vm._v("L2%")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"general-description"},[_c('p',[_vm._v(" Mean | Sign. | in %")]),_c('p',[_vm._v("(5-point-scale: ++ // + // 0 // - // -- )")]),_c('p',[_vm._v("Explanation: Significant differences between products are indicated by different letters (A≠B), (p≤0.10). Letter A states the higher value "),_c('span',{staticClass:"green"},[_vm._v("Green")]),_vm._v(" for best prototype / concept of selection; "),_c('span',{staticClass:"red"},[_vm._v("Red")]),_vm._v(" for worst prototype / concept of selection; "),_c('span',{staticClass:"white"},[_vm._v("White")]),_vm._v(" all others.")])])
}]

export { render, staticRenderFns }