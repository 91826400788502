<template>
  <nav data-app class="flex align-center flex-around">
    <span @click="handleKPIs"
          :class="{'dropdown-active' : chartDetails.menuName === 'kpis'}"
          class="kpis-button"
    >KPIs</span>
    <DropdownSelect title="General Evaluation"
                    :options="mainMenuOptions.general_evaluation"
                    menuName="general_evaluation"
                    :class="{'dropdown-active' : chartDetails.menuName === 'general_evaluation'}"
                    @handle-select-item="handleSelectQuestion"
    />
    <!--    @handle-select-item="handleSetQuestion"-->
    <!--    :class="{'dropdown-active' : getActiveMenu === 'flavour_concept_evaluation'}"-->

    <DropdownSelect title="Flavour Concept Evaluation"
                    :options="mainMenuOptions.flavour_concept_evaluation"
                    menuName="flavour_concept_evaluation"
                    @handle-select-item="handleSelectQuestion"
                    :class="{'dropdown-active' : chartDetails.menuName === 'flavour_concept_evaluation'}"
    />
    <DropdownSelect title="Taste Evaluation"
                    :options="mainMenuOptions.taste_evaluation"
                    menuName="taste_evaluation"
                    :class="{'dropdown-active' : chartDetails.menuName === 'taste_evaluation'}"
                    @handle-select-item="handleSelectQuestion"
    />
    <DropdownSelect title="Vapour & Nicotine Perception"
                    :options="mainMenuOptions.vapour_and_nicotine_perception"
                    menuName="vapour_and_nicotine_perception"
                    :class="{'dropdown-active' : chartDetails.menuName === 'vapour_and_nicotine_perception'}"
                    @handle-select-item="handleSelectQuestion"
    />
<!--    <DropdownSelect title="Flavour Specific Questions"-->
<!--                    :options="mainMenuOptions.flavour_specific_question"-->
<!--                    menuName="flavour_specific_question"-->
<!--                    :class="{'dropdown-active' : chartDetails.menuName === 'flavour_specific_question'}"-->
<!--                    @handle-select-item="handleSelectQuestion"-->
<!--    />-->
    <span @click="handleFSQ"
          :class="{'dropdown-active' : chartDetails.menuName === 'flavour_specific_question'}"
          class="kpis-button">Flavour Specific Questions</span>
  </nav>
</template>

<script>
import DropdownSelect from '@/components/DropdownSelect'

export default {
  name: "TheNavbar",
  components: {
    DropdownSelect
  },
  props: {
    mainMenuOptions: Object,
    chartDetails: Object
  },

  methods: {
    handleSelectQuestion ({objDetails, menuName}) {
      this.$emit('handle-set-item', { objDetails, menuName })
    },

    handleKPIs () {
      const currentRoute = this.$route.query
      delete currentRoute.evaluation
      let query = { ...currentRoute, section: 'kpis' }
      this.$router.replace({ query })
      this.$emit('handle-set-item', {
        objDetails: {},
        menuName: 'kpis'
      })
    },
    handleFSQ () {
      const currentRoute = this.$route.query
      delete currentRoute.evaluation
      let query = { ...currentRoute, section: 'flavour_specific_question' }
      this.$router.replace({ query })
      this.$emit('handle-set-item', {
        objDetails: { evaluation: 'flavour_specific_question', label: 'Flavour Specific Questions'},
        menuName: 'flavour_specific_question'
      })
    },
  }
}
</script>

<style lang="scss">
  @import "../styles/var";
  nav {
    padding: 0px 24px;
    border-bottom: 1px solid lightgray;
    gap: 2rem;
    span {
      white-space: nowrap;
    }
    .dropdown-active {
      background: rgb(253, 238, 223);
      box-shadow: 0 -3px 0 0 $primary inset;
    }
    .kpis-button {
      cursor: pointer;
      padding: 14.5px;
    }
  }

</style>



