import Vue from 'vue'
import Vuex from 'vuex'
import * as auth from './modules/auth'
import * as results from './modules/results'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    results
  }
})
